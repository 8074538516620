import React, { useEffect, useState } from "react";
import RadioList from "../util/RadioList";
import { GetFilters } from "../util/Api";
import * as analytics from "../util/Analytics/FilterAnalytics";
const SideFilters = ({
    card,
    handleSideFilterSelection,
    topFilterSelection,
    sideFilterParameters,
    onResetSideFilterParameters,
    sideFilterYearRef
}) => {
    const [year, setYear] = useState([]);
    const [stratificationCategoryId, setStratificationCategoryId] = useState([]);
    const [dataValueTypeId, setDataValueTypeId] = useState([]);
    const [sideFilterData, setSideFilterData] = useState(null);

    useEffect(() => {



        fetchSideFilterData();
    }, [topFilterSelection, card]);

    const selectedDataValueType =
        sideFilterData && sideFilterData.DataValueTypes
            ? sideFilterData.DataValueTypes.filter(function (item) {
                return item.DataValueTypeId === dataValueTypeId;
            })
            : null;

    const selectedYear =
        sideFilterData && sideFilterData.Years
            ? sideFilterData.Years.filter(function (item) {
                return item.YearId === year;
            })
            : null;

    const showAllYearsRadioButton =
        topFilterSelection &&
            topFilterSelection.location &&
            topFilterSelection.location.LocationAbbr &&
            topFilterSelection.location.LocationAbbr !== "ALL"
            ? true
            : false;

    const filterSelection = {
        Year: selectedYear && selectedYear.length > 0 ? selectedYear[0].Year : "All Available Years",
        YearId:
            year === "ALLYEARS" && !showAllYearsRadioButton
                ? sideFilterData.Years[0].YearId
                : year,
        StratificationCategoryId: stratificationCategoryId,
        DataValueTypeId: dataValueTypeId,
        DataValueType:
            selectedDataValueType && selectedDataValueType.length > 0
                ? selectedDataValueType[0].DataValueType
                : "",
    };


    async function fetchSideFilterData() {
        try {
            const sideFilterDataResponse = await GetFilters(
                topFilterSelection.topic.TopicId,
                topFilterSelection.location.LocationAbbr,
                card.QuestionId
            );

            //sets values filter data
            setSideFilterData(sideFilterDataResponse.data);



            if (sideFilterParameters && (sideFilterParameters.dataValueTypeIdParameter || sideFilterParameters.stratificationCategoryIdParameter || sideFilterParameters.yearIdParameter)) {


                const defaultYearId = sideFilterParameters.yearIdParameter;
                //const defaultYear = sideFilterParameters.yearIdParameter;
                const defaultStratificationCategoryId = sideFilterParameters.stratificationCategoryIdParameter;
                const defaultDataValueTypeId = sideFilterParameters.dataValueTypeIdParameter;
                //const defaultDataValueType =                    sideFilterDataResponse.data.DataValueTypes[0].DataValueType;

                handleYearChange(defaultYearId);
                handleStratificationCategoryChange(defaultStratificationCategoryId);
                handleDataValueTypesChange(defaultDataValueTypeId);

                const defaultDataValueTypeObj =
                    sideFilterDataResponse.data.DataValueTypes.filter(x => x.DataValueTypeId === sideFilterParameters.dataValueTypeIdParameter);


                const defaultDataValueTYpe = defaultDataValueTypeObj[0].DataValueType
                //build default values to pass to parent
                const defaultFilterSelections = {
                    //Year: defaultYear,
                    YearId: defaultYearId,
                    StratificationCategoryId: defaultStratificationCategoryId,
                    DataValueTypeId: defaultDataValueTypeId,
                    DataValueType: defaultDataValueTYpe,
                };

                onResetSideFilterParameters();

                //sets default values for the filter selection dom elmenets (years, data value type, view by)
                handleSideFilterSelection(defaultFilterSelections);
            } else {
                //get the defaults
                const defaultYearId = sideFilterDataResponse.data.Years[0].YearId;
                const defaultYear = sideFilterDataResponse.data.Years[0].Year;
                const defaultStratificationCategoryId =
                    sideFilterDataResponse.data.StratificationCategories[0]
                        .StratificationCategoryId;
                const defaultDataValueTypeId =
                    sideFilterDataResponse.data.DataValueTypes[0].DataValueTypeId;
                const defaultDataValueType =
                    sideFilterDataResponse.data.DataValueTypes[0].DataValueType;

                handleYearChange(defaultYearId);
                handleStratificationCategoryChange(
                    sideFilterDataResponse.data.StratificationCategories[0]
                        .StratificationCategoryId
                );
                handleDataValueTypesChange(
                    sideFilterDataResponse.data.DataValueTypes[0].DataValueTypeId
                );

                //build default values to pass to parent
                const defaultFilterSelections = {
                    Year: defaultYear,
                    YearId: defaultYearId,
                    StratificationCategoryId: defaultStratificationCategoryId,
                    DataValueTypeId: defaultDataValueTypeId,
                    DataValueType: defaultDataValueType,
                };

                //sets default values for the filter selection dom elmenets (years, data value type, view by)
                handleSideFilterSelection(defaultFilterSelections);
            }


            //set the state

        } catch (error) {
            console.log(error);
        }
    }

    function handleSubmit() {

        analytics.selectYear({ Years: filterSelection.YearId })
        analytics.selectViewBy({ ViewBy: filterSelection.StratificationCategoryId })
        analytics.selectDataType({ DataValueTypeId: filterSelection.DataValueTypeId })

        handleSideFilterSelection(filterSelection);
    }

    function handleYearChange(year) {
        setYear(year);
    }
    function handleStratificationCategoryChange(StratificationCategoryId) {
        setStratificationCategoryId(StratificationCategoryId);
    }
    function handleDataValueTypesChange(DataValueTypeId) {
        setDataValueTypeId(DataValueTypeId);
    }

    return (
        <div className="order-2 col-12 col-sm-3 order-sm-1 d-print-none ">
            {sideFilterData ? (
                <div className="sideFilter-container">
                    <>
                        <div className="sideFilter" ref={sideFilterYearRef}>
                            <RadioList
                                filterOptions={sideFilterData.Years}
                                filterDataName="Year"
                                filterDataValue="YearId"
                                filterTitle="Year"
                                filtername="year"
                                questionId={card.QuestionId}
                                onChange={handleYearChange}
                                value={year}
                                selectAll={showAllYearsRadioButton}
                                selectAllTitle={"All Available Years (Trendline)"}
                                selectAllValue="ALLYEARS"
                            />
                        </div>

                        <div className="sideFilter">
                            <div className="sideFilter-border"></div>
                            <RadioList
                                filterOptions={sideFilterData.StratificationCategories}
                                filterDataName="StratificationCategory"
                                filterDataValue="StratificationCategoryId"
                                filterTitle="View By"
                                filtername="viewBy"
                                questionId={card.QuestionId}
                                onChange={handleStratificationCategoryChange}
                                value={stratificationCategoryId}
                            />
                        </div>
                        <div className="sideFilter">
                            <div className="sideFilter-border"></div>
                            <RadioList
                                filterOptions={sideFilterData.DataValueTypes}
                                filterDataName="DataValueType"
                                filterDataValue="DataValueTypeId"
                                filterTitle="Data Type"
                                filtername="dataType"
                                questionId={card.QuestionId}
                                onChange={handleDataValueTypesChange}
                                value={dataValueTypeId}
                            />
                        </div>
                        <div className="text-left text-sm-center">
                            <button
                                className="filter-data-button btn btn-primary buttonMinWidth"
                                onClick={handleSubmit}
                            >
                                Update
                            </button>
                            <span id="filterDataLoading" className="hidden">
                                &nbsp;Loading...
                            </span>
                        </div>
                    </>
                </div>
            ) : (
                "Loading filters..."
            )}
        </div>
    );
};

export default SideFilters;
