import React, { useEffect, useState } from "react";
import LocationDropdown from "./LocationDropdown";
import TopicDropdown from "./TopicDropdown";
import * as ENV from "../util/env";
import * as analytics from "../util/Analytics/HomeAnalytics";

const TopFilter = ({
    handleViewResultsclick,    
    locationParameter,
    categoryParameter,
    topics,
    locations

}) => {
    const [location, setLocation] = useState(null);
    const [topic, setTopic] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        if (locationParameter && locationParameter.LocationAbbr) {
            analytics.selectLocation({
                locationCode: locationParameter.LocationAbbr,
            });
            setLocation(locationParameter);
        }
        if (categoryParameter && categoryParameter.TopicId) {
            analytics.selectCategory({ categoryCode: categoryParameter.TopicId });
            setTopic(categoryParameter);
        }
    }, [locationParameter, categoryParameter]);

    async function handleTopFilterSelections() {        
        await setErrorMessage(false);
        await setTopic(null);
        await setLocation(null);
        await handleViewResultsclick({ location: null, topic: null });
    }

    function openIndicatorDefinitionsLink() {
        analytics.selectIndicatorDefinitions();
        window.open(ENV.CDCBaseURL + "cdi/definitions/Indicator-definitions.html");
      }

    async function handleViewResultsButtonClick() {
        if (
            location === null ||
            location.LocationAbbr === null ||
            topic === null ||
            topic.TopicId === null
        ) {
            setErrorMessage(true);
            return;
        } else {
            setErrorMessage(false);
            await handleViewResultsclick({ location: null, topic: null });
            await handleViewResultsclick({ location, topic });

            analytics.selectLocation({ locationCode: location.LocationAbbr });
            analytics.selectCategory({ categoryCode: topic.TopicId });
        }
    }

    async function handleClearClick() {
        await setErrorMessage(false);
        await setTopic(null);
        await setLocation(null);
    }

    const LocationAbbr =
        location && location.LocationAbbr ? location.LocationAbbr : null;
    const TopicId = topic && topic.TopicId ? topic.TopicId : null;

    const submitEnabled = LocationAbbr && TopicId ? true : false;

    return (
        <div className="card bt-5 bt-primary ds-5 mb-3 d-print-none">
            <div className="card-body bg-light border rounded-bottom border-dark">
                <div className="row text-left align-items-center col-12">
                    <div className="col-md-4 topFilterSelector">
                        <LocationDropdown value={LocationAbbr} OnChange={setLocation} locations={locations} />
                    </div>
                    <div className="col-md-4 topFilterSelector">
                        <TopicDropdown value={TopicId} OnChange={setTopic} topics={topics} openIndicatorDefinitionsLink={openIndicatorDefinitionsLink}/>
                    </div>
                    <div className="col-8 col-md-2 text-right topFilterButtons">
                        <button
                            id="view-result-button"
                            className="btn btn-primary buttonMinWidth"
                            onClick={handleViewResultsButtonClick}
                            disabled={!submitEnabled}
                        >
                            View Results
                        </button>
                    </div>
                    <div
                        className="col-3 col-md-2 topFilterButtons"
                        onClick={handleTopFilterSelections}
                    >
                        <button
                            className="btn btn-link"
                            style={{ textDecoration: "underline" }}
                            onClick={handleClearClick}
                        >
                            Clear
                        </button>
                    </div>
                </div>
                {errorMessage && (
                    <div className="col-12 error-message">
                        Please select a location and a category
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopFilter;
