import React, { useEffect, useState } from "react";
const ScrollToTop = () => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showScroll, setShowScroll] = useState(false);

  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const handleScroll = () => {
    let scrollHeight = window.scrollY || window.pageYOffset;
    let maxHeight = window.document.documentElement.clientHeight;
    return scrollHeight > maxHeight
      ? setShowScroll(true)
      : setShowScroll(false);
  };

  return (
    <div
      className={
        showScroll
          ? "d-print-none row justify-content-center container__button--scroll-to-top"
          : "d-none"
      }
    >
      <button
        onClick={handleScrollToTop}
        className="btn btn-primary back-to-top"
      >
        <span className="back-to-top-arrow">&#129093;</span>
      </button>
    </div>
  );
};

export default ScrollToTop;
