import React from "react";
import * as ENV from "../util/env.js";

const Menu = ({
  handleViewComparisonReport,
  handleLinkButtonRedirect,
  handleMenuClick,
  comparisonSelected,
}) => {
  return (
    <div id="navigation" role="navigation">
      <div
        style={{
          backgroundColor: "rgba(0, 105, 92, 1)",
          height: "50px",
          marginTop: "-10px",
        }}
        className="text-left"
      >
        <h1
          className="container text-white h3"
          style={{ marginTop: "0px", paddingTop: "5px", cursor: "pointer" }}
          onClick={(e) => handleLinkButtonRedirect(ENV.CDCBaseURL + "cdi")}
        >
          Chronic Disease Indicators
        </h1>
      </div>
      {/* Mobile Menu */}
      <div
        style={{
          backgroundColor: "rgba(206, 236, 231, 1)",
          borderTop: "3px solid #4ebaaa",
          minHeight: "45px",
        }}
        className="text-left pl-1 d-md-none"
      >
        <nav className="navbar pl-3" aria-label="Mobile Navigation">
          <div className="container">
            <div className="row position-relative">
              <div className="dropdown ">
                <button
                  className="dropdown-toggle-link btn btn-link nav-link"
                  type="link"
                  aria-expanded="false"
                  onClick={handleMenuClick}
                >
                  Menu
                </button>
              </div>
            </div>
            <div className="container hidden nav-dropdown position-absolute">
              <ul className="navbar-nav mr-auto black-text-nav-links ">
                <li className="nav-item ">
                  <button
                    className="btn btn-link nav-link "
                    onClick={(e) =>
                      handleLinkButtonRedirect(ENV.CDCBaseURL + "cdi")
                    }
                  >
                    Home
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link pr-2"
                    type="link"
                    onClick={(e) => handleLinkButtonRedirect(ENV.CDIBaseURL)}
                  >
                    Data Explorer
                  </button>
                </li>
                <li className="nav-item " is-selected={comparisonSelected}>
                  <button
                    className="btn btn-link nav-link "
                    onClick={handleViewComparisonReport}
                  >
                    Comparison Report
                  </button>
                </li>
                <li className="nav-item ">
                  <button
                    className="btn btn-link nav-link "
                    onClick={(e) =>
                      handleLinkButtonRedirect(
                        "https://data.cdc.gov/browse?category=Chronic+Disease+Indicators",
                        true
                      )
                    }
                  >
                    Data Portal
                  </button>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav black-text-nav-links t">
              <li>
                <button
                  className="btn btn-link"
                  onClick={(e) =>
                    window.open(
                      ENV.CDCBaseURL + "cdi/help/index.html",
                      "_blank"
                    )
                  }
                  target="_blank"
                >
                  Help
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* Desktop Menu */}
      <div
        style={{
          backgroundColor: "rgba(206, 236, 231, 1)",
          borderTop: "3px solid #4ebaaa",
          height: "45px",
        }}
        className="text-left pl-1 d-none d-md-block"
      >
        <div className="container">
          <nav
            className="navbar navbar-expand-md"
            style={{ marginLeft: "-28px" }}
            aria-label="Desktop Navigation"
          >
            <ul className="navbar-nav mr-auto black-text-nav-links">
              <li className="nav-item ">
                <button
                  className="btn btn-link nav-link "
                  onClick={(e) =>
                    handleLinkButtonRedirect(ENV.CDCBaseURL + "cdi")
                  }
                >
                  Home
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-link nav-link"
                  onClick={(e) => handleLinkButtonRedirect(ENV.CDIBaseURL)}
                >
                  Data Explorer
                </button>
              </li>
              <li className="nav-item " is-selected={comparisonSelected}>
                <button
                  className="btn btn-link nav-link "
                  onClick={handleViewComparisonReport}
                >
                  Comparison Report
                </button>
              </li>
              <li className="nav-item ">
                <button
                  className="btn btn-link nav-link "
                  onClick={(e) =>
                    handleLinkButtonRedirect(
                      "https://data.cdc.gov/browse?category=Chronic+Disease+Indicators",
                      true
                    )
                  }
                >
                  Data Portal
                </button>
              </li>
            </ul>
            <ul className="navbar-nav black-text-nav-links">
              <li className="nav-item">
                <button
                  className="btn btn-link nav-link"
                  onClick={(e) =>
                    window.open(
                      ENV.CDCBaseURL + "cdi/help/index.html",
                      "_blank"
                    )
                  }
                >
                  Help
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        style={{ backgroundColor: "rgb(245, 245, 245)", height: "40px" }}
        className="text-left mb-2 mt-2  "
      >
        <div className="container d-print-none d-block">
          <nav
            role="navigation"
            aria-label="Breadcrumb"
            className="breadcrumbs"
          >
            <ul className="breadcrumb pt-0 pt-sm-2">
              <li className="breadcrumb-item">
                <a href={ENV.CDCBaseURL}>CDC</a>
              </li>
              <li className="breadcrumb-item">
                <a href={ENV.CDCBaseURL + "populationhealth/"}>
                  Division of Population Health
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={ENV.CDCBaseURL + "cdi/"}>Chronic Disease Indicators</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Menu;
