import React, { useEffect, useState, useRef } from "react";
import geojson from './usa-topo.json';
import Dropdown from "../../util/Dropdown";
import { feature } from "topojson-client"
import stateAbbrs from './us-abbr.json';
import * as d3 from "d3";
import './map.css'
import * as analytics from "../../util/Analytics/MapAnalytics";




let stratification = null;
let mapObject = null;
const Map = ({ tableData, sideFilterSelection, card, stratificationID, }) => {
console.log(tableData)
    const zoomInRef = useRef(null);
    const zoomOutRef = useRef(null);
    const zoomResetRef = useRef(null);

    const legendRef = useRef(null);
    const toolTipRef = useRef(null);
    const dataValueType = tableData.DataItems[0].DataValueType
    const dataValueUnit = tableData.DataItems[0].DataValueUnit
    const DataValueUnit = dataValueType === "Number" || dataValueUnit === "gallons" ? "" : "(" + dataValueUnit + ")";  

    const classificationTyes = [
        { text: 'Quantile', value: 'Quantile' },
        { text: 'Equal Interval', value: 'EqualInterval' },
        { text: 'Natural Breaks', value: 'NaturalBreak' },
        { text: 'Geometric Interval', value: 'GeometricInterval' }
    ]

    useEffect(() => {

        const legendData = BuildLegendDropdowns();

        InitializeMapData(stratificationID, legendData);

    }, [stratificationID]);
    function InitializeMapData(stratificationID, legendData) {

        ClearLocationToolTip();

        if (legendData) {



            setStratification(stratificationID);
            setClassificationType(legendData.initialClassificationType);
            setFilteredClassificationTypes(legendData.filteredClassificationTypes);
            setFilteredClassNumbers(legendData.filteredClassNumbers);
            setClassNumber(legendData.initialClassNumber);

            const tempKeyItems = tableData.StratificationKeyItems.filter(function (StratificationKeyItem) {
                return StratificationKeyItem.StratificationID === stratificationID && StratificationKeyItem.ClassNumber === legendData.initialClassNumber && StratificationKeyItem.ClassificationType === legendData.initialClassificationType;
            });

            let tempStratkeyItems = [];
            if (tempKeyItems.length > 0) {
                Class1 = tempKeyItems && tempKeyItems[0].KeyItems.length > 0 ? tempKeyItems[0].KeyItems[0].Items : [];
                Class2 = tempKeyItems && tempKeyItems[0].KeyItems.length > 1 ? tempKeyItems[0].KeyItems[1].Items : [];
                Class3 = tempKeyItems && tempKeyItems[0].KeyItems.length > 2 ? tempKeyItems[0].KeyItems[2].Items : [];
                Class4 = tempKeyItems && tempKeyItems[0].KeyItems.length > 3 ? tempKeyItems[0].KeyItems[3].Items : [];
                Class5 = tempKeyItems && tempKeyItems[0].KeyItems.length > 4 ? tempKeyItems[0].KeyItems[4].Items : [];

                tempStratkeyItems = tempKeyItems ? tempKeyItems[0].KeyItems : [];

                setInvalidKeyData(false);
            } else {
                setInvalidKeyData(true);
            }

            setStratkeyItems(tempStratkeyItems);
            mapObject = ShowMap(geojson, id, legendData.initialClassNumber);

        } else {
            
            setStratification(stratificationID);
            setClassificationType(null);
            setFilteredClassificationTypes(null);
            setFilteredClassNumbers(null);
            setClassNumber(null);
            setStratkeyItems(null);
            mapObject = ShowMap(geojson, id, 0);        
        }
    }

    function BuildLegendDropdowns(override) {
        let index = 0;

        let initialClassificationType = classificationTyes[index].value;


        const filterItems = tableData.StratificationKeyItems.filter(x => !x.HasError && x.StratificationID === stratificationID);

        if (override && override !== undefined && (override.classNumber || override.classificationType)) {
            return getLegendItem(filterItems, override.classificationType, override.classNumber);
        }

        let output = null;

        //initial check. if no items, we can't calculate
        if (!filterItems || filterItems.length === 0) return null;

        for (index = 0; index < classificationTyes.length; index++) {

            //put this into a function so that it can be called fora  single classification type and number of classes
            initialClassificationType = classificationTyes[index].value;

            output = getLegendItem(filterItems, initialClassificationType);

            if (output !== null) break;
        }
        return output;
    }

    function getLegendItem(filterItems, initialClassificationType, initialClassNumber) {
        let filteredClassificationTypes = [];
        let filteredClassNumbers = []

        const distinctClassificationTypes =
            filterItems && filterItems.length > 0
                ? filterItems.filter((value, index, self) => {
                    return (
                        self.findIndex((v) => v.ClassificationType === value.ClassificationType) === index
                    );
                })
                : [];

        filteredClassificationTypes = distinctClassificationTypes && distinctClassificationTypes.length > 0 && distinctClassificationTypes.map((item, index) => {
            return classificationTyes.filter(x => x.value === item.ClassificationType)[0];
        });

        if (!filteredClassificationTypes || filteredClassificationTypes.length === 0) return null;

        filteredClassNumbers = filterItems && filterItems.length > 0 && filterItems.filter(x => x.ClassificationType === initialClassificationType).map((item, index) => {
            return { text: item.ClassNumber, value: item.ClassNumber };
        });

        if (!filteredClassNumbers || filteredClassNumbers.length === 0) return null;


        initialClassNumber = initialClassNumber ? initialClassNumber : (filteredClassNumbers && filteredClassNumbers.length === 4 ? filteredClassNumbers[filteredClassNumbers.length - 2].value : (filteredClassNumbers.length > 0 ? filteredClassNumbers[filteredClassNumbers.length - 1].value : 0));

        if (initialClassNumber === 0 || filteredClassNumbers.length === 0 || filteredClassificationTypes.length === 0) return null;

        return { filteredClassificationTypes: filteredClassificationTypes, filteredClassNumbers: filteredClassNumbers, initialClassNumber: Number(initialClassNumber), initialClassificationType: initialClassificationType }
    }

    const smallStateIds = ['10', '11', '44'];
    const territoryIds = ['66', '72', '78'];

    const colorNoData = "#bdbdbd";
    const textNoData = "#000000"
    const territories = [{
        "id": "66",
        "properties": { "name": "Guam" }
    },
    {
        "id": "72",
        "properties": { "name": "Puerto Rico" }
    },
    {
        "type": "MultiPolygon",
        "arcs": [

        ],
        "id": "78",
        "properties": { "name": "Virgin Islands" }
    },

    ];
    const Class_7 = [
        { color: '#a7f4e4', text: '#000000' },
        { color: '#8edccc', text: '#000000' },
        { color: '#75c4b4', text: '#000000' },
        { color: '#5dac9d', text: '#000000' },
        { color: '#449587', text: '#000000' },
        { color: '#297f71', text: '#FFFFFF' },
        { color: '#00695c', text: '#FFFFFF' },
    ]
    
    const Class_6 = [
        { color: '#a7f4e4', text: '#000000' },
        { color: '#8edccc', text: '#000000' },
        { color: '#75c4b4', text: '#000000' },
        { color: '#5dac9d', text: '#000000' },
        { color: '#449587', text: '#000000' },
        { color: '#00695c', text: '#FFFFFF' },
    ]
    const Class_5 = [
        { color: '#a7f4e4', text: '#000000' },
        { color: '#8edccc', text: '#000000' },
        { color: '#5dac9d', text: '#000000' },
        { color: '#449587', text: '#000000' },
        { color: '#00695c', text: '#FFFFFF' },
    ]

    const Class_4 = [
        { color: '#a7f4e4', text: '#000000' },
        { color: '#75c4b4', text: '#000000' },
        { color: '#449587', text: '#000000' },
        { color: '#00695c', text: '#FFFFFF' },
    ]
    const Class_3 = [
        { color: '#a7f4e4', text: '#000000' },
        { color: '#5dac9d', text: '#000000' },
        { color: '#00695c', text: '#FFFFFF' },
    ]
    const Class_2 = [
        { color: '#a7f4e4 ', text: '#000000' },
        { color: '#00695c', text: '#FFFFFF' },
    ]
    // const Class_7 = [
    //     { color: '#00695c', text: '#FFFFFF' },
    //     { color: '#297f71', text: '#FFFFFF' },
    //     { color: '#449587', text: '#000000' },
    //     { color: '#5dac9d', text: '#000000' },
    //     { color: '#75c4b4', text: '#000000' },
    //     { color: '#8edccc', text: '#000000' },
    //     { color: '#a7f4e4', text: '#000000' },
    // ]

    // const Class_6 = [
    //     { color: '#00695c', text: '#FFFFFF' },
    //     { color: '#449587', text: '#000000' },
    //     { color: '#5dac9d', text: '#000000' },
    //     { color: '#75c4b4', text: '#000000' },
    //     { color: '#8edccc', text: '#000000' },
    //     { color: '#a7f4e4', text: '#000000' },
    // ]
    // const Class_5 = [
    //     { color: '#00695c', text: '#FFFFFF' },
    //     { color: '#449587', text: '#000000' },
    //     { color: '#5dac9d', text: '#000000' },
    //     { color: '#8edccc', text: '#000000' },
    //     { color: '#a7f4e4', text: '#000000' },
    // ]

    // const Class_4 = [
    //     { color: '#00695c', text: '#FFFFFF' },
    //     { color: '#449587', text: '#000000' },
    //     { color: '#75c4b4', text: '#000000' },
    //     { color: '#a7f4e4', text: '#000000' },
    // ]
    // const Class_3 = [
    //     { color: '#00695c', text: '#FFFFFF' },
    //     { color: '#5dac9d', text: '#000000' },
    //     { color: '#a7f4e4', text: '#000000' },
    // ]
    // const Class_2 = [
    //     { color: '#00695c', text: '#FFFFFF' },
    //     { color: '#a7f4e4 ', text: '#000000' }
    // ]

    const colorsArray = [Class_2, Class_3, Class_4, Class_5, Class_6, Class_7]
    const [invalidKeyData, setInvalidKeyData] = useState(false);

    const [classNumber, setClassNumber] = useState(4);
    const [classificationType, setClassificationType] = useState("Quantile");

    const [filteredClassNumbers, setFilteredClassNumbers] = useState([]);
    const [filteredClassificationTypes, setFilteredClassificationTypes] = useState([]);

    let bottom = null;

    function setStratification(value) {
        stratification = value
    }


    const [showToolTip, setShowToolTip] = useState(false);
    const [hover_x, setHoverX] = useState(0);
    const [hover_y, setHoverY] = useState(0);

    const [locationText, setLocationText] = useState("");
    const [url, setUrl] = useState("");
    const [urlText, setUrlText] = useState("");
    const [headerText, setPopupHeaderText] = useState("");

    const [popupHeaderTextColor, setPopupHeaderTextColor] = useState("");
    const [popupHeaderBackgroundColor, setPopupHeaderBackgroundColor] = useState("");

    const [ciDisplay, setCIDisplay] = useState("");

    const [StratkeyItems, setStratkeyItems] = useState([]);

    let Class1 = [];
    let Class2 = [];
    let Class3 = [];
    let Class4 = [];
    let Class5 = [];

    const id = card ? "map_" + card.QuestionId : "";

    function getColor(id, classNumber) {


        if (Class1.includes(id) && classNumber >= 2) {
            return colorsArray[classNumber - 2][0].color
        }
        else if (Class2.includes(id) && classNumber >= 2) {
            return colorsArray[classNumber - 2][1].color
        }
        else if (Class3.includes(id) && classNumber >= 3) {
            return colorsArray[classNumber - 2][2].color
        }
        else if (Class4.includes(id) && classNumber >= 4) {
            return colorsArray[classNumber - 2][3].color
        }
        else if (Class5.includes(id) && classNumber >= 5) {
            return colorsArray[classNumber - 2][4].color
        }
        else {

            return colorNoData
        }
    }
    function getTextColor(id, classNumber) {
        if (Class1.includes(id) && classNumber >= 2) {
            return colorsArray[classNumber - 2][0].text
        }
        else if (Class2.includes(id) && classNumber >= 2) {
            return colorsArray[classNumber - 2][1].text
        }
        else if (Class3.includes(id) && classNumber >= 3) {
            return colorsArray[classNumber - 2][2].text
        }
        else if (Class4.includes(id) && classNumber >= 4) {
            return colorsArray[classNumber - 2][3].text
        }
        else if (Class5.includes(id) && classNumber >= 5) {
            return colorsArray[classNumber - 2][4].text
        }
        else {

            return textNoData
        }
    }

    function HandleClassificationTypeChange(classificationType) {
        analytics.selectMapClassification({ classificationType: classificationType.value })
        const legendData = BuildLegendDropdowns({ classificationType: classificationType.value, classNumber: classNumber });
        InitializeMapData(stratification, legendData);
    }
    function HandleClassNumberChange(classNumber) {
        analytics.selectMapClasses({ numberOfClasses: classNumber.value });

        const legendData = BuildLegendDropdowns({ classificationType: classificationType, classNumber: classNumber.value });
        InitializeMapData(stratification, legendData);
    }


    let ResetZoom = () => {
        mapObject.zoomReset();
        analytics.selectMapZoom();
    }

    function ZoomIn() {
        mapObject.zoomIn();
        analytics.selectMapZoom();
    }
    function ZoomOut() {
        mapObject.zoomOut();
        analytics.selectMapZoom();
    }


    function ShowMap(mapdata, id, classNumber) {

        const width = 1200,
            height = 800;

        //remove a prior instance
        d3.select("svg").remove();

        let svg = d3.select("#" + id).append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [-75, 0, width + 200, height])
            .attr("style", "width: 100%; height: auto; height: intrinsic;");

        let g = svg.append("g");
        g.attr("id", "map")
        g.attr("class", "states")
            .selectAll("path")
            .data(feature(mapdata, mapdata.objects.states).features)
            .join('path')
            .attr('d', d3.geoPath())
            .attr('stroke', 'lightgrey')
            .attr('fill', 'grey')
            .style("stroke-width", function (d) {
                return 2;
            })
            .style("fill", function (d) {
                return getColor(d.id, classNumber);
            })
            .on('mouseover', function (d, i) {
                d.currentTarget.classList.add('hovered');
            })
            .on("mouseout", function (d) {
                d.currentTarget.classList.remove('hovered');
            })
            .on('click', function (d, i) {
                d.currentTarget.classList.add('hovered');
                ShowToolTip(d, i);
            })


        g.append("g").attr("id", "labels")
        g.selectAll("text")
            .data(feature(mapdata, mapdata.objects.states).features)
            .join('text')
            .attr("text-anchor", "middle")
            .attr('font-family', ' "Open Sans",apple-system,blinkmacsystemfont,"Segoe UI","Helvetica Neue",arial,sans-serif')
            .style('font-size', '18px')
            .style('font-weight', '50')
            .text(function (d) {
                return d.id !== '44' && d.id !== '10' && d.id !== '11' && d.id !== '66' && d.id !== '72' && d.id !== '78' ? stateAbbrs[d.id] : "";
            })
            .style("fill", function (d) {
                return getTextColor(d.id, classNumber);
            })
            .attr("x", function (d) {
                let extraX = 0
                if (d.id === 12) extraX = 10;
                if (d.id === 22) extraX = -10;
                if (d.id === 26) extraX = 5;
                return d3.geoPath().centroid(d)[0] + extraX;
            })
            .attr("y", function (d) {
                let extraY = 0;
                if (d.id === 21) extraY = 5;
                if (d.id === 26) extraY = 20;
                if (d.id === 47) extraY = 5;
                return d3.geoPath().centroid(d)[1] + extraY;
            })
            .on('click', function (d, i) {

                ShowToolTip(d, i);
            })



        var element = document.getElementById(id) ? document.getElementById(id) : null;
        var c = element ? element.getBoundingClientRect() : null;
        bottom = c && c.bottom ? c.bottom : 0;

        const smallState_x = 920;
        const smallState_y = 150;
        const smallState_width = 65;
        const smallState_height = 40;

        smallStateIds.forEach((stateId, index) => {

            g.append('g').attr("class", "states")
                .append("rect")
                .attr("class", "states")
                .attr("x", smallState_x)
                .attr("y", smallState_y + ((index + 1) * 60))
                .attr("width", smallState_width)
                .attr("height", smallState_height)
                .style("fill", function (d) {
                    return getColor(stateId, classNumber);
                })
                .attr("rx", 5)
                .attr("ry", 5)
                .style("stroke", "grey")
                .style("stroke-width", function (d) {
                    return 1.5;
                })
                .on('click', function (d, i) {
                    d.currentTarget.classList.add('hovered');
                    ShowToolTip(d, i, stateId);
                })

            g.append('g').attr("class", "states")
                .append("text")
                .attr("x", smallState_x + 30)
                .attr("y", smallState_y + 25 + ((index + 1) * 60))
                .text(stateAbbrs[stateId])
                .attr("text-anchor", "middle")
                .attr('font-family', ' "Open Sans",apple-system,blinkmacsystemfont,"Segoe UI","Helvetica Neue",arial,sans-serif')
                .style('font-size', '18px')
                .style('font-weight', '50')
                .attr('stroke', getTextColor(stateId, classNumber))
                .on('click', function (d, i) {
                    d.currentTarget.classList.add('hovered');
                    ShowToolTip(d, i, stateId);
                })


        })

        const smallTerritory_x = 0;
        const smallTerritory_y = 650;
        const smallTerritory_width = 65;
        const smallTerritory_height = 40;

        territoryIds.forEach((stateId, index) => {

            g.attr("class", "states")
                .append("rect")
                .attr("x", smallTerritory_x + ((index + 1) * 75))
                .attr("y", smallTerritory_y)
                .attr("rx", 5)
                .attr("ry", 5)
                .style("stroke", "grey")
                .style("stroke-width", function (d) {
                    return 1.5;
                })
                .attr("width", smallTerritory_width)
                .attr("height", smallTerritory_height)
                .style("fill", function (d) {
                    return getColor(stateId, classNumber);
                })
                .style("stroke", "grey")

                .on('click', function (d, i) {
                    d.currentTarget.classList.add('hovered');
                    ShowToolTip(d, i, stateId);
                })
            g.attr("class", "states")
                .append("text")
                .attr("x", smallTerritory_x + 30 + ((index + 1) * 75))
                .attr("y", smallTerritory_y + 25)
                .text(stateAbbrs[stateId])
                .attr("text-anchor", "middle")
                .attr('font-family', ' "Open Sans",apple-system,blinkmacsystemfont,"Segoe UI","Helvetica Neue",arial,sans-serif')
                .style('font-size', '18px')
                .style('font-weight', '50')
                .attr('stroke', getTextColor(stateId, classNumber))
                .on('click', function (d, i) {
                    d.currentTarget.classList.add('hovered');
                    ShowToolTip(d, i, stateId);

                })
        })

        function zoomed({ transform }) {
            g.attr("transform", transform);
        }

        const zoom = d3.zoom()
            .scaleExtent([1, 40])
            .on("zoom", zoomed);

        svg.call(zoom);


        d3.select(zoomInRef.current).on('click', function () {
            zoom.scaleBy(svg.transition().duration(500), 2);
        });

        d3.select(zoomOutRef.current).on('click', function () {
            zoom.scaleBy(svg.transition().duration(500), .5);
        });

        d3.select(zoomResetRef.current).on('click', function () {
            svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity, d3.zoomTransform(svg.node()).invert([width / 2, height / 2]))
        });
    }

    function ClearLocationToolTip() {
        setLocationText("Data unavailable")
        setCIDisplay("")
        setHoverX(0);
        setHoverY(0);
        setShowToolTip(false);
    }

    async function ShowToolTip(d, i, smallStateId) {

        analytics.selectMapSelection();

        //we have no d, so lets clear and hide
        if (d == null) {
            ClearLocationToolTip();
            return;
        }

        //get id from map hover or smallstateId (custom drawn for territories and small states)
        const id = smallStateId ? smallStateId : i.id;

        //get location item
        const filteredTableData = tableData.DataItems.filter(function (DataItem) {
            return DataItem.LocationID === id && DataItem.StratificationID === stratification;
        });
        const item = filteredTableData && filteredTableData.length && filteredTableData.length === 1 ? filteredTableData[0] : null;

        //we have an item from the dataset
        if (item) {
            const LocationName = item.LocationDesc;
            const LocationAbbr = item.LocationAbbr;
            const DataValueUnitForToolTip = item.DataValueUnit;   

            const DataValue = item.DataValueDisplay !== null ? item.DataValueDisplay : "Data unavailable";
            const LowConfidenceLimit = item.LowConfidenceLimitDisplay ? item.LowConfidenceLimitDisplay : null;
            const HighConfidenceLimit = item.HighConfidenceLimitDisplay ? item.HighConfidenceLimitDisplay : null;
            const DataValueFootnoteSymbol = item.DataValueFootnoteSymbol ? item.DataValueFootnoteSymbol : "";
            const CIDisplay = LowConfidenceLimit && HighConfidenceLimit ? "95% CI (" + LowConfidenceLimit + " - " + HighConfidenceLimit + ")" : "";
            const headerText = LocationName;
            const url = `?location=${item.LocationAbbr}&category=${card.TopicId}&indicators=ALL`

            const urlText = "View all indicators for " + LocationAbbr;

            setPopupHeaderTextColor(getTextColor(id, classNumber));
            setPopupHeaderBackgroundColor(getColor(id, classNumber));



            setLocationText(LocationName + ": " + DataValue + " " + DataValueUnitForToolTip);
            setUrl(url);
            setUrlText(urlText);
            setPopupHeaderText(headerText);
            setCIDisplay(CIDisplay + " " + DataValueFootnoteSymbol);
        }
        else if (id) {//we have an id but no record. Lets get the location name and set data unavailable
            let jsonLocation = geojson.objects.states.geometries.filter(function (item) {
                return item.id === id;
            });

            if (!jsonLocation || jsonLocation.length === 0) {
                jsonLocation = territories.filter(function (item) {
                    return item.id === id;
                });
            }

            const location = jsonLocation && jsonLocation.length > 0 ? jsonLocation[0].properties.name : "";
            setLocationText(location + ": Data unavailable")
            setCIDisplay("")
        }
        else { //lets just clear
            ClearLocationToolTip();
        }
        await setHoverY(d.layerY);
        await setShowToolTip(true);
        await setHoverX(getHoverX(d));

        const legendBounds = legendRef.current.getBoundingClientRect();
        const toolTipBounds = toolTipRef.current.getBoundingClientRect();
        
        const windowBottom = window.innerHeight || document.documentElement.clientHeight;

        //quick collision check with bottom of screen and legend
        if (toolTipBounds.right > legendBounds.left) {
            const leftShift = toolTipBounds.right > legendBounds.left ? toolTipBounds.right - legendBounds.left : 0
            await setHoverX(getHoverX(d, leftShift + 25));
        }
        if (toolTipBounds.bottom > windowBottom) {
            const bottomShift = toolTipBounds.bottom - windowBottom;
            await setHoverY(d.layerY - bottomShift - 25);
        }
    }
    function hideToolTip() {
        setShowToolTip(false);
    }
    function getHoverX(d, leftShift) {
        if (leftShift) return d.layerX - leftShift;
        return d.layerX;
    }

    function handleMapIndicatorLinkClick() {
        analytics.selectMapIndicatorLink();
    }

    return (
        <div className="text-center map-toolTipContainer pb-0 mb-0">
            
                <div className="ToolTipContainer p-0 m-0" id={"tooltip_" + id} hidden={showToolTip ? "" : "hidden"} style={{ top: hover_y + "px", left: hover_x + "px" }} ref={toolTipRef}>
                    <div className="ToolTipHeader p-0 m-0 row" style={{ backgroundColor: popupHeaderBackgroundColor }}>
                        <div className="col col-10 header-text p-1 ml-1" style={{ color: popupHeaderTextColor }}>{headerText}</div>
                        <div className="col text-right"><button onClick={hideToolTip} className="btn btn-link close-button p-0 m-0">x</button></div>

                </div>
                <div className="m-2">
                    <div className="ToolTipLocation m-1">{locationText}</div>
                    <div className="ToolTipCI m-1">{ciDisplay}</div>
                    <div className="ToolTipLink m-1"><a onClick={handleMapIndicatorLinkClick} href={url}>{urlText}</a></div>
                </div>
            </div>


            {invalidKeyData &&
                <div className="text-center mt-3">
                    No map data to show.
                </div>

            }

            <div id={id}></div>

            <div id={"MapZoom_" + id} className="MapZoomContainer ml-3 p-1 d-print-none" style={{ top: bottom + "px" }}>
                <div>
                    <button ref={zoomInRef} className="zoom-button filter-data-button btn btn-white p-1" value="zoomIn" style={{ "border": "1px solid  #bdbdbd", "color": "#00695c" }} ><span className="cdc-icon-plus"></span><p className='sr-only'>plus</p> </button>
                </div>
                <div>
                    <button ref={zoomOutRef} className="zoom-button filter-data-button btn btn-white p-1" value="zoomOut" style={{ "border": "1px solid  #bdbdbd", "color": "#00695c" }} ><span className="cdc-icon-minus"></span><p className='sr-only'>minus</p></button>
                </div>
                <div>
                    <button ref={zoomResetRef}  className="zoom-button filter-data-button btn btn-white p-1" value="zoomReset" style={{ "border": "1px solid  #bdbdbd", "color": "#00695c" }}><span className="cdc-icon-replay"></span><p className='sr-only'>reset</p></button>
                </div>
            </div>


            <div id={"keyItems_" + id} className="LegendContainer print-move-right map-mobile-scale" ref={legendRef} aria-label="Legend">
                <div className="LegendItemHeader p-2">{sideFilterSelection && sideFilterSelection.DataValueType}<br/> {DataValueUnit}</div>
                <div className="ClassesContainer p-2">
                    {StratkeyItems && StratkeyItems.length > 0 && StratkeyItems.map((item, index) => {

                        const i = StratkeyItems[index] ? StratkeyItems[index] : null;
                        const itemText = i ? i.LowValue.toFixed(1) + ' - ' + i.HighValue.toFixed(1) : "Data Unavailable";
                        const color = colorsArray && colorsArray[classNumber - 2] && colorsArray[classNumber - 2][index].color ? colorsArray[classNumber - 2][index].color : "pink"

                        return (
                            <div className="LegendItem">
                                <span className="dot" style={{ backgroundColor: color }}></span>
                                <span>{itemText}</span>
                            </div>
                        );
                    })}

                    <div className="LegendItem">
                        <span className="dot" style={{ backgroundColor: colorNoData }}></span>
                        <span>Data Unavailable</span>
                    </div>
                </div>
                <div className="ClassificationTypeContainer p-2 LegendItemContainer">
                    {filteredClassificationTypes && filteredClassificationTypes.length > 0 &&
                        <Dropdown
                            itemID="value"
                            itemName="text"
                            options={filteredClassificationTypes}
                            dropdownTitle="Classification Type"
                            dropdownID={"classificationtype" + id}
                            value={classificationType}
                            OnChange={HandleClassificationTypeChange}
                            showEmpty={false}
                        />
                    }
                </div>
                <div className="NumberOfClassesContainer p-2 LegendItemContainer">
                    {filteredClassNumbers && filteredClassNumbers.length > 0 &&
                        <Dropdown
                            itemID="value"
                            itemName="text"
                            options={filteredClassNumbers}
                            dropdownTitle="Number of Classes"
                            dropdownID={"numberOfClasses" + id}
                            value={classNumber}
                            OnChange={HandleClassNumberChange}
                            showEmpty={false}
                        />
                    }
                </div>
            </div>
        </div>)
};
export default Map;