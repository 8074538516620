import React, { useEffect } from "react";
import uuid from "react-uuid";
import DataVisualization from "./DataVisualization";

const DataVisualizationContainer = ({
  topFilterSelection,
  selectedCards,
  onTableDataChange,
  onNavigateToMapAllLocations,
  sideFilterParameters,
  onResetSideFilterParameters,
  sideFilterYearRef,
}) => {
  useEffect(() => {}, [selectedCards]);

  return selectedCards && selectedCards.length > 0
    ? selectedCards.map((card, index) => {
        return (
          <div
            key={card.TopicId + index}
            className={`col-md-12 data-visualizaion-container breakPageAfter`}
            style={{
              backgroundColor: "rgb(245, 245, 245)",
              marginBottom: "15px",
            }}
          >
            <div className="row text-left ">
              <DataVisualization
                card={card}
                index={index}
                topFilterSelection={topFilterSelection}
                onTableDataChange={onTableDataChange}
                onNavigateToMapAllLocations={onNavigateToMapAllLocations}
                sideFilterParameters={sideFilterParameters}
                onResetSideFilterParameters={onResetSideFilterParameters}
                sideFilterYearRef={sideFilterYearRef}
              />
            </div>
          </div>
        );
      })
    : "loading...";
};

export default DataVisualizationContainer;
