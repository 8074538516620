import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import QuestionTile from './QuestionTile'
import {
    GetQuestions
} from "../util/Api";
import * as analytics from "../util/Analytics/HomeAnalytics";
import uuid from "react-uuid";

const CardSection = ({
    submitClick,
    topFilterSelection, cardsToSelect, resetCardsToSelect, indicatorParameters, resetIndicatorParameters
}) => {

    useEffect(() => {

        if ((!questions || questions.length === 0) || (indicatorParameters && indicatorParameters.length > 0)) {            
            fetchQuestionsFromTopFilterSelections()
        }

    }, [indicatorParameters]);

    const [selectedCards, setSelectedCards] = useState([]);
    const [cardCountError, setCardCountError] = useState(false);
    const [showingResults, setShowingResults] = useState(false);
    const [noIndicatorsSelected, setNoIndicatorsSelected] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);

    let [selectedQuestions, setSelectedQuestions] = useState([]);
    const isAllLocations = topFilterSelection && topFilterSelection && topFilterSelection.location.LocationAbbr === "ALL" ? true : false;
    const cardLimit = topFilterSelection && topFilterSelection && topFilterSelection.location.LocationAbbr === "ALL" ? 1 : (questions && questions.length ? questions.length : 0);
    

    async function fetchQuestionsFromTopFilterSelections() {
        try {

            await setLoading(true);

            const questionResponse = await GetQuestions(
                topFilterSelection.topic.TopicId,
                topFilterSelection.location.LocationAbbr
            );

            if (indicatorParameters && indicatorParameters.length > -1) {

                //need to process parameters intead of hard coding here
                questionResponse && questionResponse.data.forEach((question, i) => {
                    selectedQuestions.push({ QuestionId: question.QuestionId, Selected: indicatorParameters.indexOf(question.QuestionId) !== -1 || indicatorParameters === "ALL" ? true : false });
                });
                resetIndicatorParameters();

                //add adobe metrics here: THIS IS FOR PAGE LOAD QUERY STRING
                if (selectedQuestions.filter(x => x.Selected === true).length > 0) {                    
                    const indicators = selectedQuestions.filter(x => x.Selected === true).map((indicator, i) => {
                        return indicator.QuestionId;
                    });
                    analytics.selectIndicator({ indicators: indicators })                    
                }
                await setSelectedQuestions(selectedQuestions);
                await setQuestions(questionResponse.data);
                resetCardsToSelect([])
                await setLoading(false);

                if (indicatorParameters && indicatorParameters.length > 0) {
                    await handlePageLoadSumbitClick(selectedQuestions, questionResponse.data)
                }
            } else {
                questionResponse && questionResponse.data.forEach((question, i) => {
                    selectedQuestions.push({ QuestionId: question.QuestionId, Selected: false });
                });

                await setSelectedQuestions(selectedQuestions);
                await setQuestions(questionResponse.data);
                resetCardsToSelect([])
                await setLoading(false);
            }

        } catch (error) {
            console.log(error);
        }
    }
    function handleQuestionSelection(value, QuestionId) {
        updateSelectedQuestions(value, QuestionId)
    }

    const updateSelectedQuestions = (value, QuestionId) => {
        const nextCardCount = cardCount + (value === true ? + 1 : -1);
        

        let newState = selectedQuestions;
        if (cardCount >= cardLimit && isAllLocations) {
            setCardCountError(true);

            newState = selectedQuestions.map(question => {

                return { ...question, Selected: false };
            });

        }
        newState = newState.map(question => {

            if (question.QuestionId === QuestionId) {
                return { ...question, Selected: value };
            }
            return question;
        });

        setSelectedQuestions(newState);
        if (nextCardCount < cardLimit) setCardCountError(false);
        setNoIndicatorsSelected(false);


    };

    const selected = selectedQuestions ? selectedQuestions.filter(function (item) {
        return item.Selected;
    }) : [];
    function handlePageLoadSumbitClick(selected, questions) {
        let selectedCards = [];
        selectedQuestions.forEach((question, i) => {
            if (selected[i].QuestionId === question.QuestionId && selected[i].Selected === true) {
                selectedCards.push(questions[i]);
            }
        });

        if (selectedCards.length === 0) {
            setNoIndicatorsSelected(true);
        } else {

            setNoIndicatorsSelected(false);
            setShowingResults(true);
            setCardCountError(false);
            setSelectedCards(selectedCards);
            submitClick(selectedCards);
        }
    }
    function handleSumbitClick() {
        let selectedCards = [];
        selectedQuestions.forEach((question, i) => {
            if (question.Selected === true) {
                selectedCards.push(questions[i]);
            }
        });

        if (selectedCards.length === 0) {
            setNoIndicatorsSelected(true);
        } else {

            setNoIndicatorsSelected(false);
            setShowingResults(true);
            setCardCountError(false);
            setSelectedCards(selectedCards);
            submitClick(selectedCards);


            const indicators = selectedCards.map((indicator, i) => {
                return indicator.QuestionId;
            });
            analytics.selectIndicator({ indicators: indicators })       
        }
    }
    function handleShowIndicators() {
        setShowingResults(false);
    }
    function handleClearAll() {
        const newState = selectedQuestions.map(question => {
            return { ...question, Selected: false };            
        });

        setSelectedQuestions(newState);
        setCardCountError(false);
        setNoIndicatorsSelected(false);
    }

    const cardCount = selected && selected.length ? selected.length : 0;
    const indicatorText = showingResults ? "Selected Indicators" : cardLimit === 1 ?"Select " + cardLimit + " indicator to view" : "Select up to "  + cardLimit + " indicators to view";


    const submitEnabled = cardCount > 0 ? true : false;

    return (
        <>
            <div className="card ds-5 mb-3 d-print-none">
                <div className="card-body bg-light border rounded border-dark">
                    <div className="row text-left">
                        <div className="col-12">
                            {loading ?
                                <span>
                                    Loading...
                                </span>
                                : <>
                                    {questions && questions.length > 0 ? <>
                                        <h2 className="h4">
                                            {indicatorText}: (<span id="card-selected-amount">{cardCount}</span> /{" "}
                                            {cardLimit}
                                            )&nbsp;
                                            {cardCountError &&
                                                <span className="error-message">
                                                    * Only {cardLimit} indicator can be selected.
                                                </span>
                                            }
                                        </h2>
                                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1000: 3 }}>
                                            {showingResults ?
                                                <Masonry columnsCount={4} gutter="8px">
                                                    {selectedCards && selectedCards.map((question, i) => (
                                                        <QuestionTile key={uuid()} question={question} index={i} isSelected={true} showingResults={true} isDisabled={false} />
                                                    ))}
                                                </Masonry>
                                                :
                                                <Masonry columnsCount={4} gutter="10px">
                                                    {selectedQuestions && questions.map((question, i) => (
                                                        <QuestionTile key={uuid()} question={question} onChange={handleQuestionSelection} index={i} isDisabled={!selectedQuestions[i].Selected && cardLimit === 1 && cardCount === 1 ? true : false} isSelected={selectedQuestions[i].Selected} showingResults={false} />
                                                    ))}
                                                </Masonry>
                                            }
                                        </ResponsiveMasonry >
                                        <div className="text-right mt-3">
                                            {showingResults ? <button className="view-all-button btn btn-primary buttonMinWidth" onClick={handleShowIndicators} > View All</button> :
                                                <>
                                                    {cardLimit > 1 && <button className="btn btn-link link-style mx-5" style={{ textDecoration: "underline" }} onClick={handleClearAll}>Clear All</button>}
                                                    <button className="submit-button btn btn-primary buttonMinWidth" onClick={handleSumbitClick} disabled={!submitEnabled}>View Results</button>
                                                </>
                                            }
                                        </div>

                                        {noIndicatorsSelected &&
                                            <span className="error-message">
                                                * Please select an Indicator.
                                            </span>
                                        }
                                    </>
                                        :
                                        <span className="error-message">
                                            No indicators to display for this location and category.
                                        </span>
                                    }
                                </>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardSection;
