import React, { useEffect } from "react";
import uuid from "react-uuid";

const ComparisonTable = ({
  tableData,
  id,
  isAllLocations,
  stratificationID,
  isAllYears,
  setTableOverFlow,
}) => {
  useEffect(() => {}, [stratificationID]);
  console.log("tableData", tableData);
  const filteredDataSets = tableData.DataItems.filter(
    (data) =>
      stratificationID === "VIEWALL" ||
      (stratificationID !== "VIEWALL" &&
        data.StratificationID === stratificationID)
  );
  console.log("filteredDataSets", filteredDataSets);
  // ******************** Below code is to show ALL strats, even those without data ********************
  const stratificationGroupedList = tableData.StratificationItems.filter(
    (strat) => strat
  );
  // ******************** Above code is to show ALL strats, even those without data ********************

  // const stratificationGroupedList = tableData.StratificationItems.filter(
  //   (strat) => strat.HasData === true
  // );

  let filteredStratificationGroupedList = stratificationGroupedList;

  if (stratificationID !== "OVR" && stratificationID === "VIEWALL") {
    filteredStratificationGroupedList.shift();
  } else if (stratificationID !== "OVR" && stratificationID !== "VIEWALL") {
    filteredStratificationGroupedList =
      filteredStratificationGroupedList.filter((strat) => {
        return strat.StratificationID === stratificationID;
      });
  }

  filteredStratificationGroupedList = filteredStratificationGroupedList.map(
    (strat) => strat.Stratification
  );

  const stratificationListLength = filteredStratificationGroupedList.length;

  const tableOverFlowClass =
    stratificationListLength >= 5 ? "table-overflow" : "";

  setTableOverFlow(tableOverFlowClass);

  const columnLength = Math.floor(12 / (stratificationListLength + 1));

  function createStateList(newDatasets) {
    return Array.from(new Set(newDatasets.map((data) => data.LocationDesc)));
  }

  function createYearList(newDatasets) {
    return Array.from(new Set(newDatasets.map((item) => item.YearEnd)));
  }

  const groupingList = isAllYears
    ? createYearList(filteredDataSets)
    : createStateList(filteredDataSets);

  function NotAllYears({ stratificationGroupedList, datasets, groupingList }) {
    let workingData = datasets;
    const dataValueType = workingData[0].DataValueType;
    const dataValueUnit = workingData[0].DataValueUnit;

    console.log("workingData", workingData);

    return (
      <table id={"Table_" + id} key={uuid()} className="table">
        <thead>
          <tr id={"tableHeadSection_" + id}>
            <th
              id="blankTopHeader"
              scope="col"
              aria-hidden="true"
              className={`border-top-0 border-left-0 col-${columnLength}`}
            >
              <h4 className="sr-only">blank</h4>
            </th>
            {stratificationGroupedList.map((stratData) => {
              return (
                <th
                  scope="col"
                  className={`table-stratification-title  col-${columnLength}`}
                >
                  {stratData}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody id={"tableRows_" + id}>
          {groupingList.map((groupingTitle, groupingIndex) => {
            return (
              <>
                {/* First Row of Table */}
                <tr className="table-primary">
                  <th
                    id="table-location-year"
                    scope="row"
                    className="table-location-year"
                    colSpan={stratificationListLength + 1}
                  >
                    {groupingTitle}
                  </th>

                  {/* {stratificationGroupedList.map(() => (
                    <td aria-label="No value"></td>
                  ))} */}
                </tr>
                {/* Second Row of Table */}
                <tr className="main-table-value">
                  <th scope="row" className="table-td">
                    {/* {dataValueType} <br />
                    {"(" + dataValueUnit + ")"} */}
                    {dataValueUnit}
                  </th>
                  {stratificationGroupedList.map((strat, tdIndex) => {
                    console.log("strat", strat);
                    const currentWorkingStrat = workingData.filter(
                      (stateInfo) => {
                        console.log("stateInfo", stateInfo);
                        return (
                          stateInfo.Stratification === strat &&
                          (stateInfo.LocationDesc === groupingTitle ||
                            stateInfo.YearEnd === groupingTitle)
                        );
                      }
                    );
                    if (
                      currentWorkingStrat !== null &&
                      currentWorkingStrat.length === 1
                    ) {
                      return (
                        <td>
                          {currentWorkingStrat[0].DataValueDisplay}{" "}
                          {currentWorkingStrat[0].DataValueFootnoteSymbol}
                        </td>
                      );
                    } else {
                      return <td>*</td>;
                    }
                  })}
                </tr>
                {/* Third Row of Table */}
                <tr className="CI-value">
                  <th id="CIndicator" scope="row" className="table-td">
                    95% CI
                  </th>
                  {stratificationGroupedList.map((strat, tdIndex) => {
                    const currentWorkingStrat = workingData.filter(
                      (stateInfo) =>
                        stateInfo.Stratification === strat &&
                        (stateInfo.LocationDesc === groupingTitle ||
                          stateInfo.YearEnd === groupingTitle)
                    );
                    if (
                      currentWorkingStrat !== null &&
                      currentWorkingStrat.length === 1
                    ) {
                      return (
                        <td>
                          {currentWorkingStrat[0].LowConfidenceLimitDisplay}{" "}
                          {" - "}{" "}
                          {currentWorkingStrat[0].HighConfidenceLimitDisplay}
                        </td>
                      );
                    } else {
                      return <td> - </td>;
                    }
                  })}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  }

  console.log("filteredDataSets", filteredDataSets);

  return (
    <>
      {stratificationID && (
        <NotAllYears
          stratificationGroupedList={filteredStratificationGroupedList}
          datasets={filteredDataSets}
          isAllLocations={isAllLocations}
          groupingList={groupingList}
        />
      )}
    </>
  );
};

export default ComparisonTable;
