import Dropdown from "../util/Dropdown";
const LocationDropdown = ({ value, OnChange, locations }) => {

  const additionalOptions = [
    { IsOptGroup: true, Label: "View All Locations" },
    { LocationAbbr: "ALL", LocationDesc: "All states, DC, and territories" },
    { IsOptGroup: true, Label: "View Specific Locations" },
  ];

  return (
    <>
      <Dropdown
        options={locations}
        itemID="LocationAbbr"
        itemName="LocationDesc"
        dropdownTitle="Location:"
        dropdownID="locationID"
        value={value}
        OnChange={OnChange}
        additionalOptions={additionalOptions}
        emptyText="Select a location"
      />
    </>
  );
};

export default LocationDropdown;
