import './DownloadSection.css'
import csvDownload from 'json-to-csv-export'
import * as analytics from "../Analytics/ComparisonAnalytics";
const DownloadSection = ({ reportItems }) => {


    function handlePrintClick() {
        analytics.selectExportPDF();
        window.print()
    }
    
    function handleDownloadClick() {
        let downloadData = [];
        if (okToDownLoad) {            

            analytics.selectExportCSV();

            reportItems && reportItems[0] && reportItems[0].data.DataItems.map((question, i) => (
                downloadData.push({ Topic: question.Topic, Indicator: question.Question, Year: question.YearStart, DataValueType: question.DataValueType +' %' })
            ))

            for (var x = 0; x < reportItems.length; x++) {

                for (var e = 0; e < downloadData.length; e++) {
                    downloadData[e]['location ' + x + 1] = reportItems[x].data.DataItems[e].LocationDesc;
                    downloadData[e]['DataValue ' + x + 1] = reportItems[x].data.DataItems[e].DataValue;                    
                    downloadData[e]['LowConfidenceLimit ' + x + 1] = reportItems[x].data.DataItems[e].LowConfidenceLimit;
                    downloadData[e]['HighConfidenceLimit ' + x + 1] = reportItems[x].data.DataItems[e].HighConfidenceLimit;
                }
            }            
        }

        const dataToConvert = {
            data: downloadData,
            filename: 'chronic_disease_indicator_comparison_report',
            delimiter: ',',
        }

        csvDownload(dataToConvert)
    }

    const okToDownLoad = reportItems && reportItems.length > 0 ? true : false;

    return (
        <>            
            <span className="cdc-icon-share-solid pl-2"></span><button className="btn btn-link export-button pl-1" aria-label="Export to PDF" onClick={handlePrintClick}>Export to PDF</button>
            <span className={"cdc-icon-excel pl-2 "} ></span><button className="btn btn-link export-button pl-1" aria-label="View Indicator Definitions." onClick={handleDownloadClick}>Export to CSV</button>
        </>
    );
};

export default DownloadSection;
