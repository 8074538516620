import { doInteraction, doAnalytics } from './Analytics';
import * as ENV from "../env";

export function pageLoaded() {
    if (ENV.EnableAnalyticInteractions) {
        doAnalytics({
            levels: ['Chronic', 'DPH', 'Chronic Disease Indicators', 'Chronic Web Application', 'DPH CDI'],
            channel: 'DPH CDI Open Data'
        });
    }
    if (ENV.EnableAnalyticConsoleLog) {
        console.log('Levels: Chronic / DPH / Chronic Disease Indicators /Chronic Web Application /DPH CDI')
        console.log('DPH CDI Open Data')
    }
}
export function selectLocation({ locationCode }) {
    doInteraction(`DPH CDI|LOCATION, ${locationCode}`);
}
export function selectCategory({ categoryCode }) {
    doInteraction(`DPH CDI|CATEGORY, ${categoryCode}`);
}
export function selectIndicator({ indicators }) {
    const interaction = `DPH CDI|INDICATOR, `;

    indicators.forEach((indicator, i) => {
        doInteraction(`${interaction}${indicator}`);
    });
}
export function selectStratification({ viewBy, stratificationId }) {
    doInteraction(`DPH CDI|STRAT, ${viewBy}, ${stratificationId}`);
}
export function selectView({ vizType }) {
    doInteraction(`DPH CDI|VIEW, ${vizType}`);
}
export function selectIndicatorDefinitionLink({ indicator }) {
    doInteraction(`DPH CDI|INDDEFLINK, ${indicator}`);
}
export function selectDataSourceLink({ dataSource }) {
    doInteraction(`DPH CDI|DATALINK, ${dataSource}`);
}
export function selectViewMapLink() {
    doInteraction(`DPH CDI|VIEWMAPLINK`);
}
export function selectTopNavButton({ topNavButton }) {
    doInteraction(`DPH CDI|TOPNAV, ${topNavButton}`);
}
export function selectIndicatorDefinitions() {
    doInteraction(`DPH CDI|NAVDEF`);
}
export function selectExportPDF() {
    doInteraction(`DPH CDI|EXPORT, PDF`);
}
export function selectExportCSV() {
    doInteraction(`DPH CDI|EXPORT, CSV`);
}


