import React from "react";

import * as d3 from "d3";
import { useEffect } from "react";

const HorizontalBarChart = ({
  datasets,
  id,
  DataValueTypeId,
  SetChartToolTip,
  ClearLocationToolTip,
  barColor,
  stratificationID,
}) => {
  useEffect(() => {
    changeData();
  }, [stratificationID]);

  function ShowHorizontalBarChart(DataItems) {
    const currentChart = document.querySelector(`svg#${id}`);
    const workingDataItems = DataItems;
    const width = 500;
    const height = 2000;
    const margin = 30;

    if (currentChart !== null) currentChart.remove();

    const svg = d3
      .select("#" + id)
      .append("svg")
      .attr("id", id)
      .attr("viewBox", `-50 0 ${width + 2 * margin} ${height + 2.5 * margin}`)
      .attr("width", "100%")
      .attr("height", height)
      .attr("preserveAspectRatio", "xMidYMin");

    const xScale = d3.scaleLinear().range([0, width]);
    const yScale = d3.scaleBand().range([0, height]).padding(0.4);

    let g = svg
      .append("g")
      .attr("class", "horizontal-chart-content")
      .attr("transform", "translate(" + 0 + "," + margin + ")")
      .on("mouseout", function (mouseEvent) {
        if (mouseEvent.toElement == null) return;
        if (mouseEvent.toElement.id === id) {
          ClearLocationToolTip("clear");
        }
      });

    yScale.domain(workingDataItems.map((d) => d.LocationDesc));

    xScale.domain([
      0,
      d3.max(workingDataItems, (d) =>
        d.HighConfidenceLimit === null
          ? d.DataValue + d.DataValue / 20
          : d.HighConfidenceLimit + d.DataValue / 20
      ),
    ]);

    const halfBarHeight = yScale.bandwidth() / 2;
    const halfTickLength = 3;

    const USValueLine = workingDataItems.filter(
      (d) => d.LocationAbbr === "US"
    )[0].DataValue;

    // X-Axis Bottom
    g.append("g")
      .attr("class", "xAxis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale).ticks(5));

    // X-Axis Top
    g.append("g")
      .attr("class", "xAxis")
      .attr("transform", "translate(0, 0)")
      .call(d3.axisTop(xScale).ticks(5));

    // Y-Axis Left
    g.append("g")
      .call(d3.axisLeft(yScale))
      .attr("id", "yLabel")
      .attr("font-size", "13px");

    let yTicks = Array.from(d3.selectAll("#yLabel .tick text"));
    yTicks.map(
      (tickLabel, index) =>
        (tickLabel.textContent = `${workingDataItems[index].DataValueFootnoteSymbol} ${tickLabel.textContent}`)
    );

    // Value Bars
    g.selectAll("rect")
      .data(workingDataItems)
      .enter()
      .append("rect")
      .attr("class", (d, i) => (i === 10 ? "bar breakPage" : "bar"))
      .attr("x", 0)
      .attr("y", (d) => yScale(d.LocationDesc))
      .attr("fill", barColor)
      .attr("width", (d) => xScale(d.DataValue))
      .attr("height", yScale.bandwidth())
      .on("mouseover", function (mouseEvent, locationInfo) {
        SetChartToolTip(mouseEvent, locationInfo);
      });

    // Confidence Limit's Horizontal Line
    g.selectAll("bar")
      .data(workingDataItems)
      .enter()
      .append("line")

      .attr("x1", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.LowConfidenceLimit);
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.HighConfidenceLimit);
      })
      .attr("y1", (d) => {
        return yScale(d.LocationDesc) + halfBarHeight;
      })
      .attr("y2", (d) => {
        return yScale(d.LocationDesc) + halfBarHeight;
      })
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Confidence Limit's Left Vertical Line
    g.selectAll("bar")
      .data(workingDataItems)
      .enter()
      .append("line")

      .attr("x1", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.LowConfidenceLimit);
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.LowConfidenceLimit);
      })
      .attr(
        "y1",
        (d) => yScale(d.LocationDesc) + halfBarHeight - halfTickLength
      )
      .attr(
        "y2",
        (d) => yScale(d.LocationDesc) + halfBarHeight + halfTickLength
      )
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Confidence Limit's Right Vertical Line
    g.selectAll("bar")
      .data(workingDataItems)
      .enter()
      .append("line")

      .attr("x1", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.HighConfidenceLimit);
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.HighConfidenceLimit);
      })
      .attr(
        "y1",
        (d) => yScale(d.LocationDesc) + halfBarHeight - halfTickLength
      )
      .attr(
        "y2",
        (d) => yScale(d.LocationDesc) + halfBarHeight + halfTickLength
      )
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    g.append("line")
      .attr("class", "USValueLine")
      .attr("x1", xScale(USValueLine))
      .attr("x2", xScale(USValueLine))
      .attr("y1", margin)
      .attr("y2", height + margin)
      .attr("stroke", "gray")
      .attr("stroke-width", 1);
  }

  let i = 0;
  function changeData() {
    ShowHorizontalBarChart(datasets.DataItems);
  }
  return (
    <>
      <div id={id} className="horizontal-chart order-2"></div>
    </>
  );
};

export default HorizontalBarChart;
