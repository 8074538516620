import React, {useState} from "react";
import "../util/table/table.css";
import ComparisonTable from "../util/table/ComparisonTable";

const TableContainer = ({
  tableData,
  id,
  topFilterSelection,
  sideFilterSelection,
  isAllYears,
  stratificationID,
}) => {

  const [tableOverFlow, setTableOverFlow] = useState("")

  
  const isAllLocations = topFilterSelection.location.LocationAbbr === "ALL";
  const selectedYear = sideFilterSelection.Year;
  const dataValueTypeTitle = sideFilterSelection.DataValueType
  const dataValueTypeUnit = dataValueTypeTitle === "Number" || tableData.DataItems[0].DataValueUnit === "gallons" ? "" : "(" + tableData.DataItems[0].DataValueUnit + ")";

  return tableData ?
    <>
    <div className="dataTypeTitle order-2 order-sm-1 text-print-scale">
          {dataValueTypeTitle + " " + dataValueTypeUnit }
      </div>
    <div className={`card-body rounded-bottom col-10 offset-1 pl-0 pt-4 table-data-visualization-container ${tableOverFlow}`} >
        <ComparisonTable
        tableData={tableData}
        id={id}
        stratificationID={stratificationID}
        isAllLocations={isAllLocations}
        selectedYear={selectedYear}
        isAllYears={isAllYears}
        setTableOverFlow = {setTableOverFlow}
        />
    </div>
        </>
    : ""
  };

export default TableContainer;
