import React, { useEffect, useState, useRef } from "react";
import { GetComparisonReport } from "../util/Api";
import "./ComparisonReport.css";
import DownloadSection from "../util/comparison-report/DownloadSection";
import LocationModal from "./LocationModal";
import { useNavigate } from "react-router-dom";
import { definitionDictionary } from "../util/definitionDictionary";
import * as ENV from "../util/env";
import CheckBox from "../util/CheckBox";
import uuid from "react-uuid";
import * as analytics from "../util/Analytics/ComparisonAnalytics";
import FootNotes from "../util/FootNotes";
const ComparisonReport = ({ locationParameter }) => {
    let navigate = useNavigate();

    useEffect(() => {
        document.title = "DPH CDI Comparison Report";

        const locationsString =
            locationParameter &&
                locationParameter.LocationAbbr &&
                locationParameter.LocationAbbr !== "ALL"
                ? locationParameter.LocationAbbr
                : "US";

        const locations = locationsString.split(",").map((x) => {
            return { LocationAbbr: x };
        });

        fetchComparisonReportLocation(locations);
    }, []);

    const searchRef = useRef(null);
    const addRemoveLocationButtonRef = useRef(null);

    const [reportItems, setReportItems] = useState([]);
    const [showLocationsModal, setShowLocationsModal] = useState(false);
    const [loadingReport, setLoadingReport] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState([
        { LocationAbbr: "US" },
    ]);
    const [footNoteItems, setFootNoteItems] = useState([])

    const [treeState, setTreeState] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [searchTermRequired, setSearchTermRequired] = useState(false);

    const [showMatchesOnly, setShowMatchesOnly] = useState(true);

    let searchCount = 0;

    async function fetchComparisonReportLocation(locations) {
        navigate(
            "?visualization=comparison-report&location=" +
            locations
                .map((x) => {
                    return x.LocationAbbr;
                })
                .join(",")
        );
        locations.forEach((x) => {
            analytics.selectSubmit(x.LocationAbbr);
        });

        try {
            setLoadingReport(true);
            let apiResponse = await GetComparisonReport(locations
                .map((x) => {
                    return x.LocationAbbr;
                })
                .join(","));

            let locationsAbbrValid = apiResponse.data.DataItems && apiResponse.data.DataItems.length > 0 

            if (!locationsAbbrValid){
                setSelectedLocations([
                    { LocationAbbr: "US" },
                ])
                return fetchComparisonReportLocation([{ LocationAbbr: "US" },])
            }

            await setReportItems([]);

            await setReportItems((reportItems) => [
                ...reportItems,
                {
                    LocationAbbr: apiResponse.data.DataItems[0].LocationAbbr,
                    data: apiResponse.data,
                },
            ])



            setSelectedLocations(locations);
            setFootNoteItems(apiResponse.data.FootNoteItems)
            await setLoadingReport(false);
        } catch (error) {
            console.log(error);
        }
    }
    const HighlightText = React.memo(({ value }) => {
        if (searchTerm === null || searchTerm === undefined || searchTerm === "")
            return value;

        const displaytext = value && value.toString ? value.toString() : value;

        return <>{getHighlightedText(displaytext, searchTerm)}</>;
    });

    function getHighlightedText(displaytext) {
        // Split text on searchterm term, include term itself into parts, ignore case
        var parts =
            displaytext && displaytext.split
                ? displaytext.split(new RegExp(`(${searchTerm})`, "gi"))
                : [];
        if (parts.length === 0) {
            const newText =
                displaytext === null || displaytext === undefined || displaytext === ""
                    ? ""
                    : displaytext;
            parts.push(newText.toString());
        }
        return parts.map((part, index) => (
            <React.Fragment key={uuid()}>
                {part.toLowerCase() === searchTerm.toLowerCase() || part === searchTerm
                    ? MarkText(part)
                    : part}
            </React.Fragment>
        ));
    }
    function MarkText(text) {
        searchCount += 1;
        return <mark>{text}</mark>;
    }

    function handleLocationChange(locations) {
        setSelectedLocations(locations);
        fetchComparisonReportLocation(locations);
    }

    async function toggleTreeNode(obj) {
        const id = obj.id;
        const currentState = isExpanded(id);
        if (currentState) {
            await setTreeState((treeState) => [
                ...treeState.filter((item) => item !== id),
            ]);
        } else {
            await setTreeState((treeState) => [
                ...treeState.filter((item) => item !== id),
                id,
            ]);
        }
    }

    const isExpanded = (id) => {
        const filteredObjectsexistingObject = treeState.filter(
            (item) => item === id
        );
        return filteredObjectsexistingObject &&
            filteredObjectsexistingObject.length > 0
            ? true
            : false;
    };

    const distinctQuestions =
        reportItems && reportItems.length > 0
            ? reportItems[0].data.DataItems.filter((value, index, self) => {
                return (
                    self.findIndex((v) => v.QuestionId === value.QuestionId) === index
                );
            })
            : [];

    const TopicTreeData =
        reportItems && reportItems.length > 0
            ? reportItems[0].data.TopicTreeData
            : null;

    const allNodes = TopicTreeData ? AllNodes(TopicTreeData) : null;

    function AllNodes(TopicTreeData) {
        let allNodes = [];
        TopicTreeData.children.map((item, i) => allNodes.push(item.id));
        return allNodes;
    }

    function calcDataValueColumnWidth(selectedLocations) {
        return 50 / selectedLocations.length + "%";
    }
    const dataValueWidth =
        selectedLocations && selectedLocations.length > 0
            ? calcDataValueColumnWidth(selectedLocations)
            : 0;

    function handleReportSearch() {
        setSearchTermRequired(false);
        const nextSearchTerm =
            searchRef && searchRef.current && searchRef.current.value
                ? searchRef.current.value
                : null;
        if (nextSearchTerm && nextSearchTerm.length > 0) {
            if (nextSearchTerm === searchTerm) return;
            analytics.selectSearch();
            setSearchTerm(searchRef.current.value);
            searchCount = 0;
            setTreeState(allNodes);
        } else if (!nextSearchTerm && searchTerm && searchTerm.length > 0) {
            setSearchTerm("");
            searchCount = 0;
            setTreeState(allNodes);
        } else {
            setSearchTermRequired(true);
        }
    }
    function handleKeyPress(e) {
        const isEnterKey = e.key && e.key === "Enter" ? true : false;

        if (isEnterKey) handleReportSearch();
    }

    function handleClearSearch() {
        setSearchTermRequired(false);
        setSearchTerm(null);
        searchRef.current.value = "";
        searchCount = 0;
    }

    function handleShowAllCheckChange(e) {
        setShowMatchesOnly(e.checked);
    }

    function GetTopicData(topicId) {
        let topicData = [];
        const topicIndicators =
            distinctQuestions && distinctQuestions.length > 0
                ? distinctQuestions.filter((x) => x.TopicId === topicId)
                : [];

        topicIndicators.forEach((indicator, i) => {
            const us = reportItems[0].data.DataItems.filter(function (
                item,
                itemIndex
            ) {
                return item.QuestionId === indicator.QuestionId && selectedLocations[0].LocationAbbr === item.LocationAbbr;
            });
            const location2 =
                selectedLocations.length > 1 &&
                reportItems[0].data.DataItems.filter(function (item, itemIndex) {
                    return item.QuestionId === indicator.QuestionId && selectedLocations[1].LocationAbbr === item.LocationAbbr;
                });
            const location3 =
                selectedLocations.length > 2 &&
                reportItems[0].data.DataItems.filter(function (item, itemIndex) {
                    return item.QuestionId === indicator.QuestionId && selectedLocations[2].LocationAbbr === item.LocationAbbr;
                });
            const location4 =
                selectedLocations.length > 3 &&
                reportItems[0].data.DataItems.filter(function (item, itemIndex) {
                    return item.QuestionId === indicator.QuestionId && selectedLocations[3].LocationAbbr === item.LocationAbbr;
                });

            const filteredIndicators = us.filter((q) => {
                const QuestionId = q.QuestionId;
                const DataValueTypeId = q.DataValueTypeId;
                const YearStart = q.YearStart;
                const YearEnd = q.YearEnd;

                const loc2Data = getItem({
                    data: location2,
                    QuestionId,
                    DataValueTypeId,
                    YearStart,
                    YearEnd,
                });
                const loc3Data = getItem({
                    data: location3,
                    QuestionId,
                    DataValueTypeId,
                    YearStart,
                    YearEnd,
                });
                const loc4Data = getItem({
                    data: location4,
                    QuestionId,
                    DataValueTypeId,
                    YearStart,
                    YearEnd,
                });

                if (
                    searchTerm === null ||
                    searchTerm === "" ||
                    !showMatchesOnly ||
                    (showMatchesOnly &&
                        (hasMatch(q) ||
                            hasMatch(loc2Data) ||
                            hasMatch(loc3Data) ||
                            hasMatch(loc4Data)))
                ) {
                    return true;
                }

                return false;
            });

            if (filteredIndicators.length > 0) {
                topicData = [...filteredIndicators];
            }
        });

        return topicData;
    }


    function getItem({ data, QuestionId, DataValueTypeId, YearStart, YearEnd }) {
        const locationData =
            data &&
            data.filter(
                (q) =>
                    q.QuestionId === QuestionId &&
                    q.DataValueTypeId === DataValueTypeId &&
                    q.YearStart === YearStart &&
                    q.YearEnd === YearEnd
            );

        if (locationData && locationData.length === 1) return locationData[0];

        return null;
    }

    function hasMatch(x) {
        if (!x) return false;
        if (!searchTerm || searchTerm === "") return true;
        if (
            x.Question &&
            x.Question.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
            return true;
        if (
            x.YearStart &&
            x.YearStart.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) >
            -1
        )
            return true;
        if (
            x.YearEnd &&
            x.YearEnd.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
            return true;
        if (
            x.DataValueType &&
            x.DataValueType.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
            return true;
        if (
            x.LocationDesc &&
            x.LocationDesc.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
            return true;
        if (
            x.LowConfidenceLimit &&
            x.LowConfidenceLimit.toString()
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) > -1
        )
            return true;
        if (
            x.HighConfidenceLimit &&
            x.HighConfidenceLimit.toString()
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) > -1
        )
            return true;
        if (
            x.DataValue &&
            x.DataValue.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) >
            -1
        )
            return true;
        return false;
    }

    function HasCategory(id) {

        const topics = reportItems[0].data.DataItems.filter(x => x.TopicId === id && hasMatch(x) === true);

        if (topics && topics.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }
    function HasCategoryIndicator(id, questionId) {

        const topics = reportItems[0].data.DataItems.filter(x => x.TopicId === id && x.QuestionId === questionId && hasMatch(x) === true);

        if (topics && topics.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }
    function HasCategoryIndicatorDataType(id, questionId, DataValueTypeId) {

        const topics = reportItems[0].data.DataItems.filter(x => x.TopicId === id && x.QuestionId === questionId && x.DataValueTypeId === DataValueTypeId && hasMatch(x) === true);

        if (topics && topics.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }
    const Category = ({ category, indicators }) => {


        const hideCategoryCss = indicators && indicators.length === 0 ? "hidden" : "";

        const showOnPrint = treeState.map((expandedTree) =>
            expandedTree === category.id ? "" : "d-print-none"
        );

        return (
            <div
                className={`row category-item-wrapper mt-2 mb-2 ${hideCategoryCss} ${showOnPrint}`}
                id={category.name}
            >
                <div
                    key={uuid()}
                    className="col-12 category-toggle"
                    onClick={() => {
                        toggleTreeNode(category);
                    }}
                >
                    <a href={"#" + category.name}>
                        {isExpanded(category.id) ? (
                            <span className="cdc-icon-minus mb-1"></span>
                        ) : (
                            <span className="cdc-icon-plus mb-1"></span>
                        )}{" "}
                        <span className="node-text">
                            <HighlightText key={uuid()} value={category.name}></HighlightText>
                        </span>
                    </a>
                </div>
                <table
                    aria-label={category.name}
                    className={"comparison-indicator-table "}
                    hidden={!isExpanded(category.id)}
                >
                    <thead>
                        <tr className="header-row">
                            <th className="header-question">
                                <HighlightText key={uuid()} value={"Indicator"}></HighlightText>
                            </th>
                            <th className="header-data-value-type">
                                <HighlightText key={uuid()} value={"Data Type"}></HighlightText>
                            </th>

                            {reportItems &&
                                selectedLocations.map((location, i) => (
                                    <th
                                        key={uuid()}
                                        className="header-data-value"
                                        style={{ width: dataValueWidth }}
                                    >
                                        <HighlightText
                                            key={uuid()}
                                            value={
                                                reportItems[0].data.DataItems.filter(x => x.LocationAbbr === location.LocationAbbr)[0].LocationDesc
                                            }
                                        ></HighlightText>
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        <IndicatorGroup
                            key={uuid()}
                            indicators={indicators}
                        ></IndicatorGroup>
                    </tbody>
                </table>
            </div>
        );
    };

    const IndicatorGroup = ({ indicators, updateItemCount }) => {
        return indicators && indicators.length > 0
            ? indicators.filter(x => HasCategoryIndicator(x.TopicId, x.QuestionId)).map((indicator, i) => {

                //if (!HasCategoryIndicator(indicator.TopicId, indicator.QuestionId)) return "";

                return (
                    <>
                        {indicator.DataValueTypes.filter(x => HasCategoryIndicatorDataType(indicator.TopicId, indicator.QuestionId, x.DataValueTypeId)).map((dataValueType, i) => {

                            //if () return "";

                            const dataValueUnit = dataValueType.DataValueUnit === "gallons" || dataValueType.DataValueType === "Number" ?
                                null :`${ dataValueType.DataValueUnit}`;
                            return (
                                <tr className="indicator-row" key={uuid()}>
                                    <td className="indicator-question">
                                        {i === 0 &&
                                            <Question indicator={indicator}></Question>
                                        }
                                    </td>
                                    <td className="indicator-data-value-type">
                                        <HighlightText
                                            key={uuid()}
                                            value={dataValueType.DataValueType}
                                        ></HighlightText>
                                        {dataValueUnit &&
                                            <>
                                                <br />
                                                <HighlightText
                                                    key={uuid()}
                                                    value={dataValueUnit}
                                                ></HighlightText>
                                            </>
                                        }
                                    <br />
                                    <HighlightText key={uuid()} value={"(95% CI)"}></HighlightText>
                                </td>
                                    {
                                selectedLocations &&
                                selectedLocations.map((location, i) => (
                                    <td key={uuid()} className="indicator-data-value">
                                        <ConfidenceInterval
                                            key={uuid()}
                                            data={
                                                reportItems[0].data.DataItems.filter(x => x.LocationAbbr === location.LocationAbbr && dataValueType.DataValueTypeId === x.DataValueTypeId && indicator.QuestionId === x.QuestionId)[0]
                                            }
                                        ></ConfidenceInterval>
                                    </td>
                                ))
                            }
                                </tr>
                )
            })}
                    </>
                )
            })
            : "";
    };

const ConfidenceInterval = ({ data, indicator }) => {
    const DataValue = data && data.DataValue ? data.DataValueDisplay : "";
    const DataValueDisplay =  data && data.DataValueFootnoteSymbol ? DataValue + " " + data.DataValueFootnoteSymbol : DataValue
    const LowCI =
      data && data.LowConfidenceLimit >= 0 ? data.LowConfidenceLimitDisplay : null;
    const HighCI =
      data && data.HighConfidenceLimit ? data.HighConfidenceLimitDisplay : null;

    const CI = DataValue === "" ? "" : LowCI && HighCI ? "(" + LowCI + " - " + HighCI + ")" : "";
    // if (!CI && !DataValue) return "No data";
    return (
        <>
            <HighlightText key={uuid()} value={DataValueDisplay}></HighlightText>
            <br />
            <HighlightText key={uuid()} value={CI}></HighlightText>
        </>
    );
};

const Question = ({ indicator }) => {
    const definitionPath =
        definitionDictionary &&
            definitionDictionary[indicator.TopicId] &&
            definitionDictionary[indicator.TopicId].definition
            ? definitionDictionary[indicator.TopicId].definition
            : "Indicator-definitions.html";

    function handleRedirectToIndicatorDefinition() {
        analytics.selectIndicatorDefinitions();

        window.open(
            ENV.CDCBaseURL +
            "cdi/definitions/" +
            definitionPath +
            "#" +
            indicator.QuestionId
        );
    }
    function handleRedirectToAllIndicator() {
        window.location =
            "?location=ALL&category=" +
            indicator.TopicId +
            "&indicators=" +
            indicator.QuestionId;
    }

    const year = indicator.YearStart !== indicator.YearEnd && indicator.YearEnd > indicator.YearStart ?
        `${indicator.YearStart}-${indicator.YearEnd}`
        : indicator.YearStart;
    return (
        <>
            <HighlightText
                key={uuid()}
                value={`${indicator.Question} \u2013 ${year}`}
            ></HighlightText>
            <br />
            <button
                className="btn btn-link p-0 m-0 d-print-none"
                onClick={handleRedirectToIndicatorDefinition}
            >
                View definition
            </button>
            <button
                className="btn btn-link p-0 m-0 float-right d-print-none"
                onClick={handleRedirectToAllIndicator}
            >
                View All Locations
            </button>
        </>
    );
};

function hideFromScreenReader() {
    let HeaderWrapper = document.querySelector(".header-wrapper");
    let NavigationItems = document.querySelector("#navigation");
    let HeaderContainer = document.querySelector(
        ".comparison-report-header-container"
    );
    let HeaderContainer2 = document.querySelector(
        ".comparison-report-header-container2"
    );
    let ReportContainer = document.querySelector(
        ".comparison-report-container"
    );
    let footer = document.querySelector("footer");

    console.log("NavigationItems", NavigationItems);
    NavigationItems.setAttribute("aria-hidden", true);
    HeaderWrapper.setAttribute("aria-hidden", true);
    HeaderContainer.setAttribute("aria-hidden", true);
    HeaderContainer2.setAttribute("aria-hidden", true);
    ReportContainer.setAttribute("aria-hidden", true);
    footer.setAttribute("aria-hidden", true);
}

function handleExpandAll() {
    if (treeState.length !== allNodes.length) {
        setTreeState(allNodes);
    }
}
function handleCollapsAll() {
    if (treeState && treeState.length > 0) {
        setTreeState([]);
    }
}

const SearchCountMessage = ({ isPrint = false }) => {
    const [message, setMessage] = useState("");

    const isMultipletimes = searchTerm && searchCount === 1 ? "time" : "times";

    const nextMessage =
        searchTerm && searchTerm.length > 0 && isPrint === false
            ? searchCount + " items"
            : isPrint === true && searchTerm !== null
                ? `${searchTerm} was found ${searchCount} ${isMultipletimes}`
                : null;

    useEffect(() => {
        setMessage(nextMessage);
    }, [searchCount]);

    return <span className="mark ml-2">{message}</span>;
};

function focusOnAddRemoveButton() {
    addRemoveLocationButtonRef.current.focus();
}

function handleOnHideModal() {
    setShowLocationsModal(false);
    focusOnAddRemoveButton();
}
function handleShowLocationModal() {
    hideFromScreenReader();
    setShowLocationsModal(!showLocationsModal);
}
return (
    <>
        <div className="row">
            <div className="col-12 col-sm-6">
                <h2 className="h4 mt-0">Comparison Report</h2>
            </div>
        </div>

        {loadingReport ? (
            "loading..."
        ) : (
            <div className="comparison-report">
                <div className="row comparison-report-header-container pt-3 pb-2 d-print-none">
                    <div className="col-12 col-sm-6">
                        <label htmlFor="txtComparisonReportSearch">Search Report:</label>{" "}
                        <input
                            id="txtComparisonReportSearch"
                            onKeyUp={(e) => handleKeyPress(e)}
                            ref={searchRef}
                            type="text"
                        ></input>{" "}
                        <button className="btn btn-primary" onClick={handleReportSearch}>
                            Search
                        </button>{" "}
                        <button className="btn btn-link" onClick={handleClearSearch}>
                            Clear
                        </button>{" "}
                        <SearchCountMessage />
                        {searchTermRequired && (
                            <div className="error-message">Please enter a search term</div>
                        )}
                    </div>
                    <div className="col-6 col-sm-3">
                        <CheckBox
                            id="showAll"
                            name="showAll"
                            checked={showMatchesOnly}
                            classNames="mt-3"
                            label="Show Matches Only"
                            onChange={handleShowAllCheckChange}
                            canCheck={true}
                        ></CheckBox>
                    </div>
                    <div className="col-6 col-sm-3 text-right">
                        <button
                            type="button"
                            className="btn btn-primary mr-5"
                            ref={addRemoveLocationButtonRef}
                            onClick={handleShowLocationModal}
                        >
                            Add/Remove Locations
                        </button>
                    </div>
                </div>

                <div className="row comparison-report-header-container2 d-print-none">
                    <div className="col-8 ml-0 pl-0 order-2 order-sm-1">
                        <button className="btn btn-link" onClick={handleExpandAll}>
                            Expand All
                        </button>{" "}
                        <button className="btn btn-link" onClick={handleCollapsAll}>
                            Collapse All
                        </button>
                    </div>
                    <div className="col-12 col-sm-4 text-right order-1 order-sm-1">
                        <DownloadSection reportItems={reportItems}></DownloadSection>
                    </div>
                </div>
                <div className="d-none d-print-block">
                    <SearchCountMessage isPrint={true} />
                </div>

                <div className="comparison-report-container">
                    {TopicTreeData &&
                        TopicTreeData.children &&
                        TopicTreeData.children.filter(x => HasCategory(x.id)).map((category, i) => (
                            <Category category={category} indicators={category.Indicators}></Category>
                        ))}
                </div>
            </div>
        )}
        {showLocationsModal && (
            <LocationModal
                onHide={handleOnHideModal}
                onSubmit={handleLocationChange}
                selectedLocations={selectedLocations}
            ></LocationModal>
        )}
        {footNoteItems &&
        <div className="mt-5">
            <FootNotes footNotes={footNoteItems} vizType="comparisonReport"/> 
        </div>
    }
    </>
);
};

export default ComparisonReport;
