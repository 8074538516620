import React from "react";

const Legend = ({ workingData }) => {
  return (
    <div className="col-12">
      <div className="row justify-content-center chartLegend">
        {workingData.map((stratification, index) => {
          return (
            <div className="trendline-legendItem mx-4 text-print-scale">
              <svg
                width="20"
                height="20"
                className={`.color_${stratification[0].legendSymbol.color}`}
              >
                <path
                  transform="translate(10,10)"
                  d={stratification[0].legendSymbol.symbol}
                  fill={stratification[0].legendSymbol.color}
                />
              </svg>
              <span>{stratification[0].name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Legend;
