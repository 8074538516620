import React, { useEffect, useState, useRef } from "react";
import { GetTable } from "../util/Api";
import MapContainer from "./MapContainer";
import ChartContainer from "./ChartContainer";
import TableContainer from "./TableContainer";
import DataVisualizationToggle from "./DataVisualizationToggle";
import SideFilters from "./SideFilters";
import DataSource from "../util/DataSource";
import FootNotes from "../util/FootNotes";
import Dropdown from "../util/Dropdown";
import * as analytics from "../util/Analytics/HomeAnalytics";
import NoData from "../util/NoData";
const DataVisualization = ({
  card,
  topFilterSelection,
  onTableDataChange,
  onNavigateToMapAllLocations,
  sideFilterParameters,
  onResetSideFilterParameters,
  sideFilterYearRef,
}) => {
  useEffect(() => {
    setSideFilterSelection(null);
  }, []);
  const defaultVizType =
    topFilterSelection.location.LocationAbbr === "ALL" ? 1 : 2;

  const [sideFilterSelection, setSideFilterSelection] = useState(null);
  const [vizType, setVizType] = useState(defaultVizType);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [selectedStratificationID, setSelectedStratificationID] =
    useState(null);
  const [currentStratification, setCurrentStratification] =
    useState("View All");

  function handleSideFilterSelection(sideFilterSelection) {
    setSideFilterSelection(sideFilterSelection);
    fetchTableData(sideFilterSelection);
  }

  async function fetchTableData(sideFilterSelection) {
    setTableData(null);
    setIsLoading(true);
    setSelectedStratificationID(null);
    try {
      const tableDataResponse = await GetTable(
        topFilterSelection.topic.TopicId,
        topFilterSelection.location.LocationAbbr,
        card.QuestionId,
        sideFilterSelection.YearId === "ALLYEARS"
          ? ""
          : sideFilterSelection.YearId,
        sideFilterSelection.DataValueTypeId,
        sideFilterSelection.StratificationCategoryId
      );

      if (
        sideFilterSelection.DataValueTypeId === "NMBR" &&
        topFilterSelection.location.LocationAbbr === "ALL"
      ) {
        setVizType(1);
      }

      setTableData(tableDataResponse.data);
      setIsLoading(false);
      onTableDataChange({
        DataItems: tableDataResponse.data.DataItems,
        QuestionId: card.QuestionId,
      });
    } catch (error) {
      console.log(error);
    }
  }
  function HandleStratificationChange(value) {
    setSelectedStratificationID(value.StratificationID);
    setCurrentStratification(value.Stratification);

    analytics.selectStratification({
      viewBy: sideFilterSelection.StratificationCategoryId,
      stratificationId: value.Stratification,
    });
    //analytics
  }

  function vizTypeChange(nextVizType) {
    setVizType(nextVizType);
  }

  function GetVisualizationByType() {
    // let newVizType = vizType === 2 && hideGraph ? 1 : vizType;
    switch (vizType) {
      case 1:
        return OkToShowMapViz ? (
          <MapContainer
            sideFilterSelection={sideFilterSelection}
            tableData={tableData}
            card={card}
            stratificationID={stratificationID}
          />
        ) : (
          "No Map Data"
        );
      case 2:
        return (
          <ChartContainer
            id={"chart_" + card.QuestionId}
            chartData={tableData}
            DataValueTypeId={DataValueTypeId}
            topFilterSelection={topFilterSelection}
            sideFilterSelection={sideFilterSelection}
            isAllYears={isAllYears}
            stratificationID={stratificationID}
            selectedStratificationHasData={selectedStratificationHasData}
            vizType={vizType}
          />
        );
      case 3:
        return (
          <TableContainer
            card={card}
            id={"table_" + card.QuestionId}
            tableData={tableData}
            topFilterSelection={topFilterSelection}
            sideFilterSelection={sideFilterSelection}
            isAllYears={isAllYears}
            stratificationID={stratificationID}
            selectedStratificationHasData={selectedStratificationHasData}
          />
        );
      default:
        return "invalid type";
    }
  }

  const LocationAbbr =
    topFilterSelection &&
    topFilterSelection.location &&
    topFilterSelection.location.LocationAbbr
      ? topFilterSelection.location.LocationAbbr
      : null;

  //  ***** Added the following because the name of the state was not showing up in the DataViz on reload. Find better way? *****
  const LocationDesc =
    tableData &&
    tableData.DataItems &&
    tableData.DataItems.length > 0 &&
    tableData.DataItems[0].LocationDesc &&
    LocationAbbr === "ALL"
      ? "All states, DC, and territories"
      : topFilterSelection &&
        topFilterSelection.location &&
        topFilterSelection.location.LocationDesc
      ? topFilterSelection.location.LocationDesc
      : null;

  const Question = card && card.Question ? card.Question : null;

  const isAllYears =
    sideFilterSelection && sideFilterSelection.YearId === "ALLYEARS"
      ? true
      : false;

  const YearText =
    sideFilterSelection && sideFilterSelection.YearId
      ? sideFilterSelection.Year
      : "All available years";

  const DataValueTypeId =
    sideFilterSelection && sideFilterSelection.DataValueTypeId
      ? sideFilterSelection.DataValueTypeId
      : null;

  const StratificationCategoryId =
    sideFilterSelection && sideFilterSelection.StratificationCategoryId
      ? sideFilterSelection.StratificationCategoryId
      : null;

  let OkToShowViz = false;

  if (
    tableData &&
    tableData.DataItems &&
    tableData.DataItems.length === 1 &&
    tableData.DataItems[0].DataValue === null
  ) {
    OkToShowViz = false;
  } else if (
    tableData &&
    tableData.DataItems &&
    tableData.DataItems.length > 0
  ) {
    OkToShowViz = true;
  }

  console.log(OkToShowViz);
  // const OkToShowViz =
  //   tableData && tableData.DataItems && tableData.DataItems.length > 0
  //     ? true
  //     : false;

  const selectedStratificationData =
    tableData === null || tableData.StratificationItems == null
      ? ""
      : tableData.StratificationItems.filter((stratification) => {
          return (
            (stratification.StratificationID === selectedStratificationID &&
              stratification.HasData === true) ||
            selectedStratificationID === "VIEWALL"
          );
        });

  const selectedStratificationHasData = selectedStratificationData.length > 0;

  const OkToShowMapViz =
    tableData &&
    tableData.StratificationKeyItems &&
    tableData.StratificationKeyItems.length > 0
      ? true
      : false;

  const dataSource =
    tableData && tableData.DataItems && tableData.DataItems.length > 0
      ? {
          DataSource: tableData.DataItems[0].DataSource,
          DataSourceUrl: tableData.DataItems[0].DataSourceUrl,
        }
      : null;

  const footNotes =
    tableData && tableData.FootNoteItems && tableData.FootNoteItems.length > 0
      ? tableData.FootNoteItems
      : [];
  const filteredFootNotes =
    footNotes && vizType === 1
      ? footNotes.filter(function (item) {
          return item.LocationAbbr !== "US";
        })
      : footNotes;

  const hideGraph =
    selectedStratificationID === "NMBR" && LocationAbbr === "ALL";

  function FilterStratificationItems(tableData) {
    let workingTableData = tableData;
    if (
      !workingTableData ||
      !workingTableData.StratificationItems ||
      !workingTableData.StratificationItems.length ||
      workingTableData.StratificationItems.length < 1
    )
      return null;

    if (LocationAbbr === "ALL") {
      workingTableData.StratificationItems =
        workingTableData.StratificationItems.filter(
          (strat) =>
            strat.HasData === true || strat.StratificationID === "VIEWALL"
        );
    }

    // let newVizType = vizType === 2 && hideGraph ? 1 : vizType;

    if (vizType === 1) {
      return workingTableData.StratificationItems.filter(function (item) {
        return vizType === 1 && item.StratificationID !== "VIEWALL";
      });
    } else if (vizType === 2) {
      return workingTableData.StratificationItems;
    } else if (vizType === 3) {
      return workingTableData.StratificationItems;
    }
  }

  // ******************** Below code is to show ALL strats, even those without data ********************
  // function FilterStratificationItems(tableData) {
  //   if (
  //     !tableData ||
  //     !tableData.StratificationItems ||
  //     !tableData.StratificationItems.length ||
  //     tableData.StratificationItems.length < 1
  //   )
  //     return null;

  //   if (vizType === 1) {
  //     return tableData.StratificationItems.filter(function (item) {
  //       return vizType === 1 && item.StratificationID !== "VIEWALL";
  //     });
  //   } else if (vizType === 2) {
  //     return tableData.StratificationItems;
  //   } else if (vizType === 3) {
  //     return tableData.StratificationItems;
  //   }
  // }
  // ******************** Above code is to show ALL strats, even those without data ********************

  const StratificationItems = FilterStratificationItems(tableData);

  //take selected or get first one to pass into dataviz item (map, graph, table)

  const stratificationID = selectedStratificationID
    ? selectedStratificationID
    : StratificationItems && StratificationItems.length > 0
    ? StratificationItems[0].StratificationID
    : null;

  // const stratificationID =
  //   selectedStratificationID === "VIEWALL" && vizType === 1
  //     ? StratificationItems[0].StratificationID
  //     : selectedStratificationID
  //     ? selectedStratificationID
  //     : StratificationItems && StratificationItems.length > 0
  //     ? StratificationItems[0].StratificationID
  //     : null;

  const showNavigateToMap =
    vizType !== 1 && LocationAbbr !== "ALL" ? true : false;

  function navigateToMapAllLocations() {
    if (LocationAbbr === "ALL" && vizType !== 1) {
      vizTypeChange(1);
    }
    if (LocationAbbr !== "ALL") {
      onNavigateToMapAllLocations(
        card,
        topFilterSelection.topic,
        sideFilterSelection
      );
      vizTypeChange(1);
    }
  }

  const viewByTitle = String(
    StratificationCategoryId === "RACE"
      ? "Race/Ethnicity"
      : StratificationCategoryId
  ).toLowerCase();

  return (
    <>
      <SideFilters
        handleSideFilterSelection={handleSideFilterSelection}
        topFilterSelection={topFilterSelection}
        card={card}
        sideFilterParameters={sideFilterParameters}
        onResetSideFilterParameters={onResetSideFilterParameters}
        sideFilterYearRef={sideFilterYearRef}
      />

      <div className="order-1 col-12 col-sm-9 print-col-12 order-sm-2 DataVizContainer">
        {tableData ? (
          <div className="text-center  DataVizHeaderContainer">
            <div className="row mt-3">
              <div className="col-12 offset-0 col-sm-8 offset-sm-2 print-col-12 offset-print-0">
                <h2 className="mb-1 mt-1 text-print-scale h4">
                  <strong>{Question}</strong>
                </h2>
                <h3 className="mb-1 mt-1 text-print-scale h5">
                  {LocationDesc} | {YearText}
                </h3>
                <h3 className="mb-1 mt-1 viewByTitle text-print-scale h5">
                  View by: {viewByTitle}
                </h3>
              </div>
              <div className="col-6 offset-6 order-first col-sm-2 offset-sm-0 order-sm-last d-print-none">
                {showNavigateToMap && (
                  <button
                    className="btn btn-link pt-0 mt-0"
                    onClick={navigateToMapAllLocations}
                  >
                    <div className="row map-icon-container">
                      <div className="col-2">
                        <span className="cdc-icon-map_01 map-icon-green"></span>
                      </div>
                      <div className="col-1 p-0 m-0"></div>
                      <div className="col-8 map-icon-text pr-0 pl-2">
                        View map for all locations
                      </div>
                    </div>
                  </button>
                )}
              </div>
            </div>

            {StratificationItems && StratificationItems.length > 1 && (
              <div className="row stratification-container">
                <div className="col-md-6 offset-md-3 stratification-label print-col-12">
                  <div className="text-center">
                    <div className="d-none stratification-label d-print-inline-block text-print-scale">
                      Stratification: {currentStratification}
                    </div>
                    <div className="stratification-dropdown row d-inline-block d-print-none">
                      <Dropdown
                        itemID="StratificationID"
                        itemName="Stratification"
                        options={StratificationItems}
                        dropdownTitle="Stratification:"
                        dropdownID={"Stratification" + card.QuestionId}
                        value={stratificationID}
                        OnChange={HandleStratificationChange}
                        showEmpty={false}
                        extraClassForTitle="stratification-label d-inline-block pr-1 d-print-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <DataVisualizationToggle
              vizTypeChange={vizTypeChange}
              value={vizType}
              defaultViz={defaultVizType}
              showMapVizType={LocationAbbr === "ALL" ? true : false}
              showGraphVizType={
                DataValueTypeId === "NMBR" && LocationAbbr === "ALL"
                  ? false
                  : true
              }
            />

            {OkToShowViz ? (
              <div className="text-center">{GetVisualizationByType()}</div>
            ) : (
              <NoData vizType={vizType} />
            )}

            <FootNotes
              footNotes={filteredFootNotes}
              vizType={vizType}
            ></FootNotes>
            <DataSource dataSource={dataSource} card={card}></DataSource>
          </div>
        ) : (
          <></>
        )}
        {isLoading && <div className="mt-1">loading data...</div>}
      </div>
    </>
  );
};

export default DataVisualization;
