import React, { useEffect } from "react";
import uuid from "react-uuid";

const Dropdown = ({
  options,
  dropdownTitle,
  dropdownID,
  value,
  OnChange,
  additionalOptions,
  itemID,
  itemName,
  showEmpty,
  emptyText,
  extraClassForTitle,
}) => {
  useEffect(() => {}, [value]);

  if (showEmpty === undefined) showEmpty = true;
  function handleOnChange(e) {
    const value =
      e.target.value !== null && e.target.value !== "" ? e.target.value : null;
    const index = e.target.selectedIndex;
    const text = e.target.options[index].text;

    OnChange({ [itemName]: text, [itemID]: value });
  }

  const selectedValue = value && value != null ? value : "";
  return (
    <>
      <div className="form-group">
        <label
          htmlFor={dropdownID}
          className={extraClassForTitle === "" ? "h4" : extraClassForTitle}
        >
          {dropdownTitle}
          <select
            className="form-control"
            id={dropdownID}
            onChange={handleOnChange}
            required={true}
            value={selectedValue}
            name={`${dropdownTitle}`}
          >
            {showEmpty && (
              <option key={uuid()} value="">
                {emptyText}
              </option>
            )}

            {additionalOptions &&
              additionalOptions.map((option, index) => {
                return option.IsOptGroup ? (
                  <optgroup key={uuid()} label={option.Label}></optgroup>
                ) : (
                  <option key={uuid()} value={option[itemID]}>
                    {option[itemName]}
                  </option>
                );
              })}

            {options &&
              options.map((option) => {
                return (
                  <option key={uuid()} value={option[itemID]}>
                    {option[itemName]}
                  </option>
                );
              })}
          </select>
        </label>
      </div>
    </>
  );
};

export default Dropdown;
