
export const definitionDictionary = {
    ALC: {        
        definition: 'alcohol.html'        
    },
    ART: {
        definition: 'arthritis.html'
    },    
    AST: {
        definition: 'asthma.html'
    },
    CAN: {
        definition: 'cancer.html'
    },
    CHC: {
        definition:'cognitive-health-and-caregiving.html'
    },
    CVD: {
        definition: 'cardiovascular-disease.html'
    },
    CKD: {
        definition: 'chronic-kidney.html'
    },
    COPD: {
        definition: 'chronic-obstructive.html'
    },
    DIA: {
        definition: 'diabetes.html'
    },
    DIS: {
        definition: 'disability.html'
    },
    HEA: {
        definition: 'health-status.html'
    },
    IMM: {
        definition: 'immunization.html'
    },
    MAT: {
        definition: 'maternal-health.html'
    },
    MEN: {
        definition: 'mental-health.html'
    },
    NPAW: {
        definition: 'nutrition-physical.html'
    },
    OLD: {
        definition: 'older-adults.html'
    },
    ORH: {
        definition: 'oral-health.html'
    },
    OVC: {
        definition: 'overarching-conditions.html'
    },
    RPH: {
        definition: 'reproductive-health.html'
    },
    SLEP: {
        definition: 'sleep.html'
    },
    SDOH: {
        definition:'social-determinants.html'
    },
    STH: {
        definition:'schoolhealth.html'
    },
    TOB: {
        definition: 'tobacco.html'
    },
};

