import React, { useState } from "react";
import uuid from "react-uuid";

const RadioList = ({
  filterOptions,
  filterDataName,
  filterDataValue,
  filterTitle,
  questionId,
  onChange,
  value,
  selectAll,
  selectAllTitle,
  selectAllValue,
}) => {
  const [showingAllYears, setShowingAllYears] = useState(false);

  let filterClassName = filterTitle.split(" ").join("-") + "-Radio-Button";
  let filterName = questionId + "-" + filterClassName;

  const selectAllOption =
    selectAll && filterOptions.length > 1 ? (
      <>
        <div className="row" key={uuid()}>
          <div className=" col-1">
            <input
              type="radio"
              id={filterName + questionId + "all"}
              checked={selectAllValue === value}
              value={selectAllValue}
              disabled={!selectAll}
              filtername={filterName}
              name={filterName}
              className={filterClassName}
              onChange={handleRadioButtonChange}
            />
          </div>
          <div className="col-10 pl-2">
            <label htmlFor={filterName + questionId + "all"}>
              {selectAllTitle}
            </label>
          </div>
        </div>
      </>
    ) : null;

  function handleRadioButtonChange(e) {
    const value = e && e.target ? e.target.value : null;
    onChange(value);
  }

  function handleShowAllYears() {
    setShowingAllYears(true);
  }

  const recordCount = filterOptions && filterOptions ? filterOptions.length : 0;
  const isHidingYears = recordCount > 4 ? true : false;
  return (
    <div className="bs-1">
      <fieldset>
        <legend className="sr-only">Select the {filterTitle}</legend>
        <label>
          <div className="h5">{filterTitle}</div>
          <div>
            {selectAllOption}

            {filterOptions &&
              filterOptions.length > 0 &&
              filterOptions.map((filter, index) => {
                if (index > 4 && !showingAllYears) return "";

                return (
                  <div key={uuid()} className="row">
                    <div className=" col-1">
                      <input
                        id={filterName + filter[filterDataName]}
                        type="radio"
                        checked={filter[filterDataValue] === value}
                        value={filter[filterDataValue]}
                        filtername={filterName}
                        name={filterName}
                        className={filterClassName}
                        onChange={handleRadioButtonChange}
                      />
                    </div>
                    <div className="col-10 pl-2">
                      <label htmlFor={filterName + filter[filterDataName]}>
                        {filter[filterDataName]}
                      </label>
                    </div>
                  </div>
                );
              })}

            {isHidingYears && !showingAllYears && (
              <button className="btn btn-link" onClick={handleShowAllYears}>
                See more
              </button>
            )}
          </div>
        </label>
      </fieldset>
    </div>
  );
};

export default RadioList;
