import React, { useEffect } from "react";

import Map from "../util/map/map"
const MapContainer = ({ tableData, id, sideFilterSelection, card, stratificationID }) => {

    useEffect(() => {

    }, []);


    return (
        <div className="card-body rounded-bottom col-12 pl-0 pt-0">
            <Map tableData={tableData} id={id} sideFilterSelection={sideFilterSelection} card={card} stratificationID={stratificationID} />
        </div>
    );
};

export default MapContainer;
