import React from "react";

const NoData = ({ vizType }) => {
  // const hasNoData =
  //   (tableData && tableData.DataItems && tableData.DataItems.length === 0) ||
  //   hasDataToShow === false
  //     ? true
  //     : false;

  // function GetNoDataErrorMessageByVisualizationType() {
  //   switch (vizType) {
  //     case 1:
  //       return "No data to display for map view.";
  //     case 2:
  //       return "No data to display for graph view.";
  //     case 3:
  //       return "No data to display for table view.";
  //     default:
  //       return "No data to display.";
  //   }
  // }

  // if (!hasNoData) return "";
  return (
    <div className="row">
      <div className="col-12 text-center">
        <div className="no-data mt-3 p-3">
          {/* {GetNoDataErrorMessageByVisualizationType()} */}
          No data to display for this view.
        </div>
      </div>
    </div>
  );
};

export default NoData;
