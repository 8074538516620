import React, { useEffect } from "react";
import uuid from "react-uuid";

const FootNotes = ({ footNotes, vizType }) => {
  useEffect(() => {}, [footNotes]);

  if (!footNotes || footNotes.length === 0) return;

  const hortizonalChartClass = vizType === "2" ? "horizontal-chart-space" : "";

  return (
    <div className={`row mb-3 ml-2 mt-3 mt-sm-0 ${hortizonalChartClass}`}>
      {footNotes &&
        footNotes.map((footNotes, i) => (
          <>
            <div className="col-11 pl-0" key={uuid()}>
              <div className="mb-3 pl-0">
                <div className="row ml-0 text-left">
                  <div className="col-2 col-md-1 pl-0 foot-notes text-print-scale">
                    {footNotes.DataValueFootnoteSymbol}
                  </div>
                  <div className="col-6 col-md-10 pl-0 foot-notes text-print-scale">
                    {footNotes.DataValueFootnote}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default FootNotes;
