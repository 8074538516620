import Dropdown from "../util/Dropdown";
const TopicDropdown = ({
  value,
  OnChange,
  topics,
  openIndicatorDefinitionsLink,
}) => {
  return (
    <>
      <div className="indicatorDefinition d-block col">
        <button
          className="btn btn-link"
          aria-label="View Indicator Definitions"
          onClick={openIndicatorDefinitionsLink}
        >
          Indicator Definitions by Topic
        </button>
      </div>

      <Dropdown
        options={topics}
        itemID="TopicId"
        itemName="Topic"
        dropdownTitle="Topic:"
        dropdownID="TopicID"
        value={value}
        OnChange={OnChange}
        emptyText="Select a topic"
      />
    </>
  );
};

export default TopicDropdown;
