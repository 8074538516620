import React, { useEffect, useState } from "react";
import LineChart from "../util/chart/LineChart";
import ComparisonLineChart from "../util/chart/ComparisonLineChart";
import VerticalBarChart from "../util/chart/VerticalBarChart";
import HorizontalBarChart from "../util/chart/HorizontalBarChart";
import "../util/chart/chart.css";
import * as d3 from "d3";
import HorizontalBarChartNotOverallViewAll from "../util/chart/HorizontalBarChartNotOverallViewAll";
import NoData from "../util/NoData";

const ChartContainer = ({
  chartData,
  id,
  DataValueTypeId,
  topFilterSelection,
  sideFilterSelection,
  isAllYears,
  stratificationID,
  vizType,
}) => {
  useEffect(() => {
    getChartType();
  }, [stratificationID]);
  const isUnitedStates =
    topFilterSelection.location.LocationAbbr === "ALL" ? true : false;
  const isViewAll = stratificationID === "VIEWALL" ? true : false;

  let lineChart = isAllYears === true;
  let comparisonLinechart = lineChart && isViewAll === true;

  const [showToolTip, setShowToolTip] = useState(false);
  const [locationInfo, setLocationInfo] = useState(false);
  const [hover_x, setHoverX] = useState(0);
  const [hover_y, setHoverY] = useState(0);
  const [deviceType, setDeviceType] = useState("");

  window.addEventListener("resize", () => {
    const newDeviceType = window.outerWidth < 767 ? "mobile" : "desktop";
    setDeviceType(newDeviceType);
  });

  const symbolSize = 150;

  const CirclePath = d3.symbol().type(d3.symbolCircle).size(symbolSize)();
  const SquarePath = d3.symbol().type(d3.symbolSquare).size(symbolSize)();
  const TrianglePath = d3.symbol().type(d3.symbolTriangle).size(symbolSize)();
  const StarPath = d3.symbol().type(d3.symbolStar).size(symbolSize)();
  const DiamondPath = d3.symbol().type(d3.symbolDiamond).size(symbolSize)();
  const CrossPath = d3.symbol().type(d3.symbolCross).size(symbolSize)();
  const WyePath = d3.symbol().type(d3.symbolWye).size(symbolSize)();
  const PlusPath = d3.symbol().type(d3.symbolPlus).size(symbolSize)();

  // 1.      Dark Teal: #006666
  // 2.      Dark Orange: #CC6600
  // 3.      Light Teal: #339999
  // 4.      Pink: #993366
  // 5.      Purple: #660066
  // 6.      Light Blue: #336699
  // 7.      Dark Blue: #003366
  const defaultColor = "#006666";

  const barColors = [
    defaultColor,
    "#CC6600",
    "#339999",
    "#993366",
    "#660066",
    "#336699",
    "#003366",
  ];

  const legendSymbols = [
    { symbol: CirclePath, color: defaultColor },
    { symbol: SquarePath, color: barColors[1] },
    { symbol: TrianglePath, color: barColors[2] },
    { symbol: StarPath, color: barColors[3] },
    { symbol: DiamondPath, color: barColors[4] },
    { symbol: CrossPath, color: barColors[5] },
    { symbol: WyePath, color: barColors[6] },
    { symbol: PlusPath, color: barColors[7] },
  ];

  const stratificationList = Array.from(
    new Set(chartData.DataItems.map((item) => item.Stratification))
  );

  let datasets = { ...chartData };
  let comparisonLineChartDataSet = [];
  stratificationList.forEach((stratification, index) => {
    const stratificationYearList = datasets.DataItems.filter(
      (dataItem) => dataItem.Stratification === stratification
    );
    comparisonLineChartDataSet.push(stratificationYearList);
  });

  function wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/[ ]+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1,
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", `${++lineNumber * lineHeight + dy}em`)
            .text(word);
        }
      }
    });
  }

  const dataValueTypeTitle =
    isUnitedStates === true ? `${sideFilterSelection.DataValueType}` : "";
  const dataValueTypeUnit =
    datasets.DataItems[0].DataValueUnit === "Number" ||
    datasets.DataItems[0].DataValueUnit === "gallons"
      ? ""
      : `(${datasets.DataItems[0].DataValueUnit})`;
  const dataValueTypeUnitDisplay =
    isUnitedStates === true ? dataValueTypeUnit : "";

  console.log(datasets.DataItems);

  let workingData;
  let hasDataToShow =
    datasets.DataItems.filter((item) => item.DataValue !== null).length > 0;

  if (stratificationID !== "VIEWALL") {
    workingData = datasets.DataItems.filter(
      (data) => data.StratificationID === stratificationID
    );
    datasets.DataItems = workingData;
    hasDataToShow =
      workingData &&
      workingData.length > 0 &&
      workingData[0].DataValue !== null;
  }

  function getChartType() {
    const horizontalBarChart = isUnitedStates === true;
    const horizontalBarChartNotOverallViewAll =
      horizontalBarChart === true && stratificationID === "VIEWALL";
    const verticalBarChart = isAllYears === false;
    lineChart = isAllYears === true;

    comparisonLinechart = lineChart && isViewAll === true;

    return horizontalBarChartNotOverallViewAll === true ? (
      <HorizontalBarChartNotOverallViewAll
        datasets={datasets}
        id={id}
        wrap={wrap}
        DataValueTypeId={DataValueTypeId}
        SetChartToolTip={SetChartToolTip}
        ClearLocationToolTip={ClearLocationToolTip}
        barColors={barColors}
        stratificationID={stratificationID}
        isUnitedStates={isUnitedStates}
      />
    ) : horizontalBarChart === true ? (
      <HorizontalBarChart
        datasets={datasets}
        id={id}
        DataValueTypeId={DataValueTypeId}
        SetChartToolTip={SetChartToolTip}
        ClearLocationToolTip={ClearLocationToolTip}
        barColor={defaultColor}
        stratificationID={stratificationID}
      />
    ) : verticalBarChart === true ? (
      <VerticalBarChart
        datasets={datasets}
        id={id}
        wrap={wrap}
        DataValueTypeId={DataValueTypeId}
        SetChartToolTip={SetChartToolTip}
        ClearLocationToolTip={ClearLocationToolTip}
        legendSymbols={legendSymbols}
        stratificationID={stratificationID}
        deviceType={deviceType}
      />
    ) : comparisonLinechart === true ? (
      <ComparisonLineChart
        datasets={datasets}
        id={id}
        SetChartToolTip={SetChartToolTip}
        ClearLocationToolTip={ClearLocationToolTip}
        stratificationID={stratificationID}
        comparisonLineChartDataSet={comparisonLineChartDataSet}
        legendSymbols={legendSymbols}
        deviceType={deviceType}
      />
    ) : lineChart === true && comparisonLinechart === false ? (
      <LineChart
        datasets={datasets}
        id={id}
        SetChartToolTip={SetChartToolTip}
        ClearLocationToolTip={ClearLocationToolTip}
        barColor={defaultColor}
        stratificationID={stratificationID}
        deviceType={deviceType}
      />
    ) : (
      ""
    );
  }

  function ClearLocationToolTip(mouseEvent) {
    if (
      mouseEvent === "clear" ||
      mouseEvent.toElement.id === id ||
      mouseEvent.toElement.id === undefined
    ) {
      setShowToolTip(false);
      setLocationInfo(null);
    }
  }

  function SetChartToolTip(mouseEvent, locationInfo, toolTipOffSet = 1) {
    setLocationInfo(locationInfo);
    setShowToolTip(true);
    setHoverX(getHoverX(mouseEvent, toolTipOffSet));
    setHoverY(getHoverY(mouseEvent, toolTipOffSet));
  }

  const ToolTip = ({ locationInfo, ref }) => {
    const toolTipLocationOrYear = isAllYears
      ? locationInfo.YearEnd
      : locationInfo.LocationDesc;
    const valueSymbol =
      locationInfo.DataValueUnit === null
        ? ""
        : // : locationInfo.DataValueUnit === "%"
          // ? locationInfo.DataValueUnit
          " " + locationInfo.DataValueUnit;

    const CIValue = `95% CI (${locationInfo.LowConfidenceLimitDisplay} - ${locationInfo.HighConfidenceLimitDisplay})`;
    const footnote = locationInfo.DataValueFootnoteSymbol;

    return (
      <div
        id={`ToolTipContainer_${id}`}
        className="ToolTipContainer"
        style={{ top: hover_y + "px", left: hover_x + "px" }}
      >
        <div className="float-right">
          <button onClick={CloseToolTip} className="btn btn-link close-button">
            x
          </button>
        </div>

        <div className="ToolTipLocation">
          <p className="toolTipLocationOrYear">{toolTipLocationOrYear}</p>
          <p className="toolTipViewBy">{locationInfo.Stratification}</p>
          <p className="toolTipDataValue">
            {locationInfo.DataValueDisplay}
            <span className="noTextTransformation">{valueSymbol}</span>{" "}
            {footnote}
          </p>
          {locationInfo.LowConfidenceLimit !== null && (
            <p className="toolTipCI">
              <span>
                {CIValue}
                {/* {" "}
                95% CI \(${locationInfo.LowConfidenceLimitDisplay} - $
                {locationInfo.HighConfidenceLimitDisplay}) */}
              </span>
            </p>
          )}
        </div>
      </div>
    );
  };

  function CloseToolTip(e) {
    e.target.parentElement.parentElement.classList.add("hidden");
  }

  function getHoverY(mouseEvent, toolTipOffSet) {
    const mouseY = mouseEvent.offsetY * toolTipOffSet + 20;
    return mouseY;
  }
  function getHoverX(mouseEvent, toolTipOffSet) {
    const mouseX = mouseEvent.offsetX * toolTipOffSet;
    return mouseX;
  }

  return (
    <div className="card-body rounded-bottom col-12 pl-0 pt-0 data-visualization-container">
      {showToolTip && <ToolTip locationInfo={locationInfo}></ToolTip>}

      <div className="dataTypeTitle order-2 order-sm-1 text-print-scale">
        {isUnitedStates
          ? dataValueTypeTitle + " " + dataValueTypeUnitDisplay
          : dataValueTypeTitle}
      </div>
      {/* {hasDataToShow || isUnitedStates ? ( */}
      {/* {hasDataToShow ? getChartType() : <NoData vizType={vizType} />} */}
      {getChartType()}
    </div>
  );
};

export default ChartContainer;
