import React, { useEffect, useState } from "react";
import './InfoDownloadSection.css'
import csvDownload from 'json-to-csv-export'
import * as analytics from "../util/Analytics/HomeAnalytics";
const InfoDownloadSection = ({ tableData}) => {

    useEffect(() => {
        setDownloadClicked(false);
    }, [tableData]);

    const [downloadClicked, setDownloadClicked] = useState(false);
    
    function printToPDF() {
        analytics.selectExportPDF();
        window.print()
    }

    function handleDownloadClick() {
        setDownloadClicked(true);

        if (okToDownLoad) {
            let downloadData = [];

            analytics.selectExportCSV();

            tableData.sort((a, b) => {
                return a.QuestionId - b.QuestionId;
            });
            
            tableData.forEach((indicatorItems) => {
                indicatorItems && indicatorItems.DataItems && indicatorItems.DataItems.forEach((item, index) => {
                    downloadData.push(item);
                });
            });
            const dataToConvert = {
                data: downloadData,
                filename: 'chronic_disease_indicator',
                delimiter: ',',                
            }
            csvDownload(dataToConvert)
        }
    }
    
    const okToDownLoad = tableData && tableData.length > 0 ? true : false;
    return (
        <>
            <div className="col-12 text-left text-sm-right d-print-none">
                <span className="cdc-icon-share-solid pl-2"></span><button className="btn btn-link export-button pl-1" aria-label="Export to PDF" onClick={printToPDF}>Export to PDF</button>
                <span className={"cdc-icon-excel pl-2 " + (!okToDownLoad ? "" : "disabled")} ></span><button className="btn btn-link export-button pl-1" aria-label="Export to CSV." onClick={handleDownloadClick}>Export to CSV</button>

            </div>
            {!okToDownLoad && downloadClicked &&
                <div className="col-12 error-message">
                    No data to download.
                </div>
            }
        </>
    );
};
export default InfoDownloadSection;