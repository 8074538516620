import { doInteraction } from './Analytics';
export function selectExportPDF() {
    doInteraction(`DPH CDI|COMP, EXPORT, PDF`);
}
export function selectExportCSV() {
    doInteraction(`DPH CDI|COMP, EXPORT, CSV`);
}
export function selectSearch() {
    doInteraction(`DPH CDI|COMP, SEARCH`);
}
export function selectIndicatorDefinitions() {
    doInteraction(`DPH CDI|NAVDEF`);
}
export function selectSubmit(location) {
    doInteraction(`DPH CDI|COMP, LOCATION, ${location}`);
}
