let CDCBaseURL = "";
let APIBaseURL = "";
let CDIBaseURL = "";
let EnableAnalyticInteractions = false;
let EnableAnalyticConsoleLog = false;
const HOST_ENV = getHostEnvironment();

function getHostEnvironment() {
    var hostName = window.location.hostname;
    if (hostName.includes("localhost")) return "localhost";
    if (hostName.includes("-qa.apps.ecpaas-dev.cdc.gov")) return "qa";
    if (hostName.includes("-dev.apps.ecpaas-dev.cdc.gov")) return "dev";
    if (hostName.includes("-int.apps.ecpaas.cdc.gov")) return "int";
    // default to prod
    return "prod";
} // .getHostEnvironment

switch (HOST_ENV) {
    case "localhost":
        //APIBaseURL = 'https://localhost:7057/api';
        APIBaseURL = "https://cdi-dev.apps.ecpaas-dev.cdc.gov/api";
        CDCBaseURL = "https://wwwdev.cdc.gov/";
        CDIBaseURL = "/";        
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = false;
        break;
    case "dev":
        APIBaseURL = "/api";
        CDCBaseURL = "https://wwwdev.cdc.gov/";
        CDIBaseURL = "/";        
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = false;
        break;
    case "qa":
        APIBaseURL = "/api";
        CDCBaseURL = "https://wwwdev.cdc.gov/";
        CDIBaseURL = "/";        
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = false;
        break;
    case "int":
        APIBaseURL = "/api";
        CDCBaseURL = "https://www.cdc.gov/";
        CDIBaseURL = "/";        
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = false;
        break;
    case "prod":
        APIBaseURL = "/api";
        CDCBaseURL = "https://www.cdc.gov/";
        CDIBaseURL = "/";        
        EnableAnalyticInteractions = true;
        EnableAnalyticConsoleLog = false;
        break;
    default:
}

module.exports = {
    APIBaseURL: APIBaseURL,
    CDCBaseURL: CDCBaseURL,
    CDIBaseURL: CDIBaseURL,    
    EnableAnalyticInteractions: EnableAnalyticInteractions,
    EnableAnalyticConsoleLog: EnableAnalyticConsoleLog,
    DataCDCLink: "https://data.cdc.gov/browse?category=Chronic+Disease+Indicators",
    CDIHelpLink: CDCBaseURL + "cdi/help/index.html",
    DivisionPopulationHelpLink: CDCBaseURL + "populationhealth/"
};
