import React, { useEffect, useState } from "react";
import { definitionDictionary } from "../util/definitionDictionary";
import * as ENV from "./env";
import * as analytics from "../util/Analytics/HomeAnalytics";
const DataSource = ({ dataSource, card }) => {
  useEffect(() => {
    if (card && card.TopicId) {
      const definitionPath =
        definitionDictionary &&
        definitionDictionary[card.TopicId] &&
        definitionDictionary[card.TopicId].definition
          ? definitionDictionary[card.TopicId].definition
          : "Indicator-definitions.html";

      setSefinitionPath(definitionPath);
    }
  }, [dataSource, card]);

  const [definitionPath, setSefinitionPath] = useState(null);

  function handleRedirectToDataSourceInfo() {
    window.open(dataSource.DataSourceUrl);

    analytics.selectDataSourceLink({ dataSource: dataSource.DataSource });
  }
  function handleRedirectToIndicatorDefinition() {
    window.open(
      ENV.CDCBaseURL +
        "cdi/definitions/" +
        definitionPath +
        "#" +
        card.QuestionId
    );
    analytics.selectIndicatorDefinitionLink({ indicator: card.TopicId });
  }

  if (!dataSource) return "";
  return definitionPath ? (
    <div className="row ml-2">
      <div className="col-12 text-left p-0 m-0 d-print-none ">
        <button
          className="btn btn-link p-0 m-0"
          onClick={handleRedirectToIndicatorDefinition}
        >
          Indicator Definition
        </button>
      </div>
      <div className="col-12 text-left p-0 m-0 text-print-scale data-source-label">
        <b>Data Source: </b>
        <button
          className="btn btn-link text-print-black text-print-scale"
          onClick={handleRedirectToDataSourceInfo}
        >
          {dataSource.DataSource}
        </button>
      </div>
    </div>
  ) : (
    "error - cannot display data source component"
  );
};

export default DataSource;
