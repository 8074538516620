import React from "react";
import uuid from "react-uuid";

const CheckBox = ({
    id, name, checked, classNames, label, onChange
}) => {
    
    function handleCheckChange(e) {
        const checked = e && e.target ? e.target.checked : false;
        onChange({ id: id, checked: checked })
    }
    function handleOnFocusChange(e) {
        
    }
    return (
        <div>
            <input
                key={uuid()}
                type="checkbox"
                id={id}
                name={name}
                checked={checked}
                className={classNames}
                onChange={handleCheckChange}
            />    
            &nbsp;
            <label  htmlFor={id}> {label} </label>
        </div>
    );
}; 

export default CheckBox;
