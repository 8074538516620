import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { CDCHeader, CDCBody, CDCFooter } from "@cdcent/templatepackage-react";
import "@cdcent/templatepackage-react/assets/css/bootstrap.min.css";
import "@cdcent/templatepackage-react/assets/css/app.min.css";
import "@cdcent/templatepackage-react/assets/css/theme-teal.min.css";
import TopFilter from "./components/TopFilter";
import DataVisualizationContainer from "./components/DataVisualizationContainer";
import CardSection from "./components/CardSection";
import InfoDownloadSection from "./components/InfoDownloadSection";
import ComparisonReport from "./components/ComparisonReport";
import ScrollToTop from "./util/ScrollToTop";
import * as analytics from "./util/Analytics/HomeAnalytics";
import Menu from "./components/Menu";
import { GetTopics, GetLocations } from "./util/Api";
const App = () => {
    let navigate = useNavigate();

    useEffect(() => {
        //set levels
        analytics.pageLoaded();

        PageInit();
    }, []);

    const [errorLoadingData, setErrorLoadingData] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);

    const [locations, setLocations] = useState([]);
    const [topics, setTopics] = useState([]);

    async function handleResetIndicatorParameters() {
        await setIndicatorParameters([]);
    }
    async function handleResetSideFilterParameters() {
        await setSideFilterParameters([]);
    }
    async function PageInit() {
        let topicsData = [];
        let locationsData = [];

        await setDataLoading(true);

        await Promise.all([
            (async () => {
                topicsData = await fetchTopics();
                locationsData = await fetchLocations();
            })(),
        ]);


        if (!topicsData || !locationsData || topicsData.length === 0 || locationsData.length === 0) {
            //clear and set error 
            await setErrorLoadingData(true);
            await setTopics([]);
            await setLocations([]);
            await setDataLoading(false);
        } else {
            //set location and topics data
            await setLocations(locationsData);
            await setTopics(topicsData);

            //clear data loading and error (just incase)
            await setDataLoading(false);
            await setErrorLoadingData(false);

            //get query string params
            const urlParameters = new URLSearchParams(window.location.search);
            const locationParameter =
                urlParameters.get("location") === null
                    ? null
                    : urlParameters.get("location");
            const categoryParameter =
                urlParameters.get("category") === null
                    ? null
                    : urlParameters.get("category");
            const indicatorParameters =
                urlParameters.get("indicators") === null
                    ? null
                    : urlParameters.get("indicators");
            const dataVizParameter =
                urlParameters.get("visualization") === null
                    ? null
                    : urlParameters.get("visualization");
            const viewmaps =
                urlParameters.get("viewmaps") === null
                    ? null
                    : urlParameters.get("viewmaps");
            const viewgraphs =
                urlParameters.get("viewgraphs") === null
                    ? null
                    : urlParameters.get("viewgraphs");
            const viewtrends =
                urlParameters.get("viewtrends") === null
                    ? null
                    : urlParameters.get("viewtrends");

            
            const dataValueTypeIdParameter =
                urlParameters.get("DataValueTypeId") === null
                    ? null
                    : urlParameters.get("DataValueTypeId");
            const stratificationCategoryIdParameter =
                urlParameters.get("StratificationCategoryId") === null
                    ? null
                    : urlParameters.get("StratificationCategoryId");
            const yearIdParameter =
                urlParameters.get("YearId") === null
                    ? null
                    : urlParameters.get("YearId");

            //process query string params
            await processParametersForPageLoad({
                locationParameter,
                categoryParameter,
                indicatorParameters,
                viewmaps,
                viewgraphs,
                viewtrends,
                dataVizParameter,
                dataValueTypeIdParameter, stratificationCategoryIdParameter, yearIdParameter, locationsData
            });
        }
    }
    async function processParametersForPageLoad({
        locationParameter,
        categoryParameter,
        indicatorParameters,
        dataVizParameter,
        dataValueTypeIdParameter, stratificationCategoryIdParameter, yearIdParameter, locationsData
    }) {
        await setShowingComparisonReport(false);
        await setSelectedCards([]);
        await setTableData([]);

        if (dataVizParameter === "comparison-report") {
            locationParameter = locationParameter.replace("ALL", "US");
            await setLocationParameter({ LocationAbbr: locationParameter });
            await setShowingComparisonReport(true);
        } else {
            //more refinement needed

            const location = locationParameter === "ALL" ? locationsData : locationsData.filter(x => x.LocationAbbr === locationParameter)
            const locationDesc = location !== null && location.length > 0 ? location[0].LocationDesc : null

            await setLocationParameter({ LocationAbbr: locationParameter });
            await setCategoryParameter({ TopicId: categoryParameter });
            await setTopFilterSelection({
                location: { LocationAbbr: locationParameter, LocationDesc: locationDesc },
                topic: { TopicId: categoryParameter },
            });


            const list = indicatorParameters ? indicatorParameters.split(',') :null;

            if (list && list.length === 1) {
                const sideFilterParameters = { dataValueTypeIdParameter, stratificationCategoryIdParameter, yearIdParameter }
                await setSideFilterParameters(sideFilterParameters);
            }

            await setIndicatorParameters(indicatorParameters);

            
            
        }
    }
    
    const [showingComparisonReport, setShowingComparisonReport] = useState(false);
    const [locationParameter, setLocationParameter] = useState(null);
    const [categoryParameter, setCategoryParameter] = useState(null);
    const [indicatorParameters, setIndicatorParameters] = useState([]);
    const [sideFilterParameters, setSideFilterParameters] = useState(null);
    const [topFilterSelection, setTopFilterSelection] = useState({
        location: null,
        topic: null,
    });
    const [selectedCards, setSelectedCards] = useState([]);
    const [cardsToSelect, setCardsToSelect] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [navSelected, setNavSelected] = useState("");


    async function fetchTopics() {
        const response = await GetTopics();
        return response && response.data ? response.data : null;
    }
    async function fetchLocations() {
        const response = await GetLocations();
        return response && response.data ? response.data : null;
    }

    async function handleViewResultsclick(nextTopFilterSelection) {
        await setSelectedCards([]);
        await setTopFilterSelection(nextTopFilterSelection);
        await setTableData([]);

        if (
            nextTopFilterSelection &&
            nextTopFilterSelection.location != null &&
            nextTopFilterSelection.topic.TopicId != null
        ) {
            navigate(
                "/?location=" +
                nextTopFilterSelection.location.LocationAbbr +
                "&category=" +
                nextTopFilterSelection.topic.TopicId
            );
        } else {
            navigate("/");
        }
    }

    async function handleIndicatorSubmitClick(selectedCards) {
        await setSelectedCards([]);
        await setSelectedCards(selectedCards);
        await setTableData([]);
        if (selectedCards && selectedCards.length > 0) {
            let indicators = [];
            selectedCards.map((question, i) => (
                indicators.push(question.QuestionId)
            ));

            navigate(
                "/?location=" +
                topFilterSelection.location.LocationAbbr +
                "&category=" +
                topFilterSelection.topic.TopicId +
                "&indicators=" +
                indicators.join(",")
            );
        }
    }

    const sideFilterYearRef = useRef(null);
    //very important logic here. careful with changes
    async function handleNavigateToMapAllLocations(
      selectedCard,
      topic,
      sideFilterSelection
    ) {
      let selectedYear = sideFilterSelection.YearId;
      if (sideFilterSelection.YearId === "ALLYEARS") {
        selectedYear =
          sideFilterYearRef.current.querySelectorAll("input")[1].value;
      }
      window.location =
        "?location=ALL&category=" +
        topic.TopicId +
        "&indicators=" +
        selectedCard.QuestionId +
        "&DataValueTypeId=" +
        sideFilterSelection.DataValueTypeId +
        "&StratificationCategoryId=" +
        sideFilterSelection.StratificationCategoryId +
        "&YearId=" +
        selectedYear;
  
      analytics.selectViewMapLink();
    }

    function handleViewComparisonReport() {
        analytics.selectTopNavButton({ topNavButton: "Comparison Report" });
        setNavSelected("comparison");
        navigate("?visualization=comparison-report&location=US");
        setShowingComparisonReport(true);
    }

    const handleLinkButtonRedirect = (url, newWindow = false) => {
        newWindow === true  
        ? window.open(url)
        : window.location.href = url;
    };

    function handleMenuClick() {
        const dropdownMenu = document.querySelector(".nav-dropdown");
        dropdownMenu.classList.toggle("hidden");
    }

    async function handleTableDataChange(data) {
        await setTableData((tableData) => [
            ...tableData.filter((item) => item.QuestionId !== data.QuestionId),
            data,
        ]);
    }

    const OkToShowCards =
        topFilterSelection &&
            topFilterSelection.location &&
            topFilterSelection.location.LocationAbbr &&
            topFilterSelection.topic &&
            topFilterSelection.topic.TopicId
            ? true
            : false;

    const OkToShowDataViz =
        OkToShowCards && selectedCards && selectedCards.length > 0 ? true : false;
    const comparisonSelected =
        navSelected && navSelected === "comparison" ? "true" : "false";

    const hasTopicsAndLocations = !topics || !locations || topics.length === 0 || locations.length === 0 ? false : true;
    return (
        <div style={{ backgroundColor: "rgb(245, 245, 245)" }}>
            <CDCHeader search={false}></CDCHeader>
            <Menu
                handleViewComparisonReport={handleViewComparisonReport}
                handleLinkButtonRedirect={handleLinkButtonRedirect}
                handleMenuClick={handleMenuClick}
                comparisonSelected={comparisonSelected}
            />
            <CDCBody>
                {errorLoadingData &&
                    < div className="alert alert-danger" role="alert">The application was unable to load data. Please refresh the page and try again.</div>
                }
                {dataLoading &&
                    <span>loading</span>
                }
                {hasTopicsAndLocations &&
                    <>

                        {showingComparisonReport ? (
                            <ComparisonReport locationParameter={locationParameter} />
                        ) : (
                            <>

                                <TopFilter
                                    locationParameter={locationParameter}
                                    categoryParameter={categoryParameter}
                                    handleViewResultsclick={handleViewResultsclick}
                                    topics={topics}
                                    locations={locations}
                                />


                                {OkToShowCards && (
                                    <>
                                        <CardSection
                                            topFilterSelection={topFilterSelection}
                                            cardsToSelect={cardsToSelect}
                                            indicatorParameters={indicatorParameters}
                                            submitClick={handleIndicatorSubmitClick}
                                            resetCardsToSelect={setCardsToSelect}
                                            resetIndicatorParameters={handleResetIndicatorParameters}
                                        />
                                    </>
                                )}
                                <div className="container">
                                    <div className="row">
                                        {OkToShowDataViz && (
                                            <>
                                                <InfoDownloadSection
                                                    tableData={tableData}
                                                    topFilterSelection={topFilterSelection}
                                                    selectedCards={selectedCards}
                                                ></InfoDownloadSection>
                                                <DataVisualizationContainer
                                                    topFilterSelection={topFilterSelection}
                                                    selectedCards={selectedCards}
                                                    onTableDataChange={handleTableDataChange}
                                                    onNavigateToMapAllLocations={
                                                        handleNavigateToMapAllLocations
                                                    }
                                                    sideFilterParameters={sideFilterParameters}
                                                    onResetSideFilterParameters={handleResetSideFilterParameters }
                                                    sideFilterYearRef={sideFilterYearRef}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        <ScrollToTop />
                    </>
                }

            </CDCBody>
            <div className="d-print-none">
                <CDCFooter></CDCFooter>
            </div>
        </div>
    );
};

export default App;
