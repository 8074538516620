import axios from "axios";
import * as ENV from "../util/env";
async function GetLocations() {
    return await axios.get(ENV.APIBaseURL + "/locations")
        .catch(function (error) {
            console.log(error.toJSON());
        });
}

async function GetTopics() {
    return await axios.get(ENV.APIBaseURL + "/Topics")
        .catch(function (error) {
            console.log(error.toJSON());
        });
}

async function GetQuestions(TopicID, LocationAbbr) {    

    return await axios.get(
        ENV.APIBaseURL +
        "/Questions?TopicId=" +
        TopicID +
        "&LocationAbbr=" +
        LocationAbbr
    ).catch(function (error) {
        console.log(error.toJSON());
    });
}

async function GetFilters(TopicID, LocationAbbr, QuestionID) {

    return await axios.get(
        ENV.APIBaseURL +
        "/Filters?&TopicId=" +
        TopicID +
        "&LocationAbbr=" +
        LocationAbbr +
        "&QuestionId=" +
        QuestionID
    ).catch(function (error) {
        console.log(error.toJSON());
    });
}
async function GetComparisonReport(location) {

    return await axios.get(ENV.APIBaseURL + "/ComparisonReportData?&LocationAbbr=" + location
    ).catch(function (error) {
        console.log(error.toJSON());
    });
}
async function GetTable(
    TopicID,
    LocationAbbr,
    QuestionID,
    YearID,
    DataValueTypeID,
    StratificationCategoryId
) {
    LocationAbbr = LocationAbbr === "ALL" ? "" : LocationAbbr;

    return await axios.get(
        ENV.APIBaseURL +
        "/TableData?TopicId=" +
        TopicID +
        "&LocationAbbr=" + LocationAbbr +
        "&QuestionId=" +
        QuestionID +
        "&YearID=" +
        YearID +
        "&DataValueTypeId=" +
        DataValueTypeID +
        "&StratificationCategoryId=" +
        StratificationCategoryId
    ).catch(function (error) {
        console.log(error.toJSON());
    });


}

export { GetLocations, GetTopics, GetQuestions, GetFilters, GetTable, GetComparisonReport };
