import React from "react";
import * as d3 from "d3";
import { useEffect } from "react";

const HorizontalBarChartNotOverallViewAll = ({
  datasets,
  id,
  DataValueTypeId,
  SetChartToolTip,
  ClearLocationToolTip,
  barColors,
  stratificationID,
}) => {
  useEffect(() => {
    changeData();
  }, [stratificationID]);

  let datasetsItemsArray = Array.from(datasets.DataItems);
  const isDataValueTypeIdNMBR = DataValueTypeId === "NMBR";
  const dataByStateNotOrdered = d3.group(
    datasetsItemsArray,
    (d) => d.LocationDesc
  );
  const arrayOfStrats = Array.from(dataByStateNotOrdered).map(
    (state) => state[1]
  );
  const orderedArrayOfStrats = arrayOfStrats.map((array) => array.slice());
  const stateList = Array.from(
    new Set(datasetsItemsArray.map((d) => d.LocationDesc))
  );
  const stratificationKeys = orderedArrayOfStrats[1].map(
    (d) => d.Stratification
  );
  let newStateData = {};

  for (let i = 0; i < dataByStateNotOrdered.size; i++) {
    const stateName = stateList[i];
    newStateData[stateName] = orderedArrayOfStrats[i];
  }

  const dataByState = new Map(Object.entries(newStateData));
  const currentChart = document.querySelector(`svg#${id}`);
  const margin = { top: 30, right: 20, bottom: 50, left: 190 };
  const chartShift = 150;
  const viewboxWidth = 550;
  const viewboxHeight = 1750 * stratificationKeys.length;
  const width = viewboxWidth - margin.left - margin.right;
  const height = viewboxHeight - margin.top - margin.bottom;
  const legendWidth = (viewboxWidth / 0.75) * 0.25 + margin.right;

  function ShowHorizontalBarChartNotOverallViewAll() {
    if (currentChart !== null) currentChart.remove();
    if (isDataValueTypeIdNMBR === true) return;

    const svg = d3
      .select("#" + id)
      .append("svg")
      .attr("id", id)
      .attr("viewBox", `0 0 ${viewboxWidth} ${viewboxHeight}`)
      .attr("width", "75%")
      .attr("height", "75%")
      .attr("transform", "translate(-100, 0)")
      .attr("preserveAspectRatio", "xMidYMin");

    const maxXValue = d3.max(datasetsItemsArray, (dataItem) =>
      dataItem.HighConfidenceLimit === null
        ? dataItem.DataValue + dataItem.DataValue / 20
        : dataItem.DataValue === null
        ? 0
        : dataItem.HighConfidenceLimit + dataItem.DataValue / 20
    );

    const xScale = d3
      .scaleLinear()
      .nice()
      .domain([0, maxXValue])
      .range([0, width]);

    let g = svg
      .append("g")
      .attr("class", "horizontal-viewall-content")
      .attr("transform", "translate(" + 0 + "," + 0 + ")");

    g.append("g")
      .attr("id", "xAxis")
      .attr("transform", `translate(${chartShift}, ${height + margin.top})`)
      .call(
        d3
          .axisBottom(xScale)
          .scale(xScale)
          .ticks(5)
          .tickSize(0)
          .tickSizeInner(-height)
      );

    g.append("g")
      .attr("transform", `translate(${chartShift}, ${margin.top})`)
      .call(d3.axisTop(xScale).ticks(5).tickSize(0));

    function addFootnoteToLabel(stateName, stateIndex) {
      let needsFootnote = false;
        let workingIndex = null;
        stratificationKeys.forEach((d, stratificationIndex) => {
        workingIndex =
          stateIndex * stratificationKeys.length + stratificationIndex;

        if (needsFootnote === false) {
          needsFootnote =
            datasetsItemsArray[workingIndex].DataValueFootnoteSymbol === ""
              ? false
              : true;
        }
      });
      return needsFootnote === true
        ? `${datasetsItemsArray[workingIndex].DataValueFootnoteSymbol} ${stateName}`
        : stateName;
    }

    const yScale = d3
      .scaleBand()
      .domain(stateList)
      .range([0, height])
      .padding(0.2);

    g.append("g")
      .attr("id", "yLabel")
      .attr("transform", `translate(${chartShift}, ${margin.top})`)
      .call(
        d3
          .axisLeft(yScale)
          .tickFormat(addFootnoteToLabel)
          .tickSize(0)
          .tickPadding(8)
      )
      .selectAll("text")
      .attr("font-size", "11");

    g.append("g")
      .attr("id", "stateSeparators")
      .attr(
        "transform",
        `translate(${chartShift}, ${
          margin.top + height / (dataByState.size * 2)
        })`
      )

      .call(d3.axisRight(yScale).tickFormat("").tickSize(20).tickPadding(8));

    let xGridlines = Array.from(d3.selectAll("#xAxis .tick line"));
    xGridlines.map((gridline) => (gridline.attributes[0].value = "#d3d3d3"));

    const ySubgroups = d3
      .scaleBand()
      .domain(stratificationKeys)
      .range([0, yScale.bandwidth()])
      .padding(0);

    const currentBarColors = stratificationKeys.map((d, i) => barColors[i]);

    const color = d3
      .scaleOrdinal()
      .domain(stratificationKeys)
      .range(currentBarColors);

    const halfTickLength = 3;

    let bars = g
      .append("g")
      .attr("transform", `translate(0, ${margin.top})`)
      .selectAll("g")
      .data(dataByState)
      .join("g")
      .attr("transform", (d) => `translate(${chartShift}, ${yScale(d[0])})`)
      .selectAll("rect")
      .data((d) => {
        return d[1];
      })
      .join("rect")
      .attr("class", (d, i) => `bar color_${i}`)
      .attr("x", xScale(0))
      .attr("y", (d) => ySubgroups(d.Stratification))
      .attr("width", (d) => xScale(d.DataValue))
      .attr("height", ySubgroups.bandwidth())
      .attr("fill", (d) => color(d.Stratification))
      .on("mouseover", function (mouseEvent, locationInfo) {
        SetChartToolTip(mouseEvent, locationInfo);
      })
      .on("mouseout", function (mouseEvent) {
        if (mouseEvent.toElement == null) return;
        if (mouseEvent.toElement.id === id) {
          ClearLocationToolTip(mouseEvent);
        }
      })
      .select("text");
    const barHeight = document.querySelector(".bar").height.baseVal.value;

    // Confidence Limit's Horizontal Line
    g.append("g")
      .attr("class", "lineContainer")
      .attr("transform", `translate(${chartShift}, ${margin.top})`)
      .selectAll("g")
      .data(dataByState)
      .join("g")
      .selectAll("rect")
      .data((d) => {
        return d[1];
      })
      .join("line")
      .attr("x1", (d, i) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.LowConfidenceLimit);
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.HighConfidenceLimit);
      })
      .attr("y1", (d, i) => {
        if (d.DataValue === null) return 0;
        return yScale(d.LocationDesc) + barHeight / 2 + barHeight * i;
      })
      .attr("y2", (d, i) => {
        if (d.DataValue === null) return 0;
        return yScale(d.LocationDesc) + barHeight / 2 + barHeight * i;
      })
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Confidence Limit's Left Vertical Line
    g.append("g")
      .attr("class", "lineContainer")
      .attr("transform", `translate(${chartShift}, ${margin.top})`)
      .selectAll("g")
      .data(dataByState)
      .join("g")
      .selectAll("rect")
      .data((d) => {
        return d[1];
      })
      .join("line")
      .attr("x1", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.LowConfidenceLimit);
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.LowConfidenceLimit);
      })
      .attr("y1", (d, i) => {
        if (d.DataValue === null) return 0;
        return (
          yScale(d.LocationDesc) +
          barHeight / 2 +
          barHeight * i -
          halfTickLength
        );
      })
      .attr("y2", (d, i) => {
        if (d.DataValue === null) return 0;
        return (
          yScale(d.LocationDesc) +
          barHeight / 2 +
          barHeight * i +
          halfTickLength
        );
      })
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    // Confidence Limit's Right Vertical Line
    g.append("g")
      .attr("class", "lineContainer")
      .attr("transform", `translate(${chartShift}, ${margin.top})`)
      .selectAll("g")
      .data(dataByState)
      .join("g")
      .selectAll("rect")
      .data((d) => {
        return d[1];
      })
      .join("line")
      .attr("x1", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.HighConfidenceLimit);
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.HighConfidenceLimit);
      })
      .attr("y1", (d, i) => {
        if (d.DataValue === null) return 0;
        return (
          yScale(d.LocationDesc) +
          barHeight / 2 +
          barHeight * i -
          halfTickLength
        );
      })
      .attr("y2", (d, i) => {
        if (d.DataValue === null) return 0;
        return (
          yScale(d.LocationDesc) +
          barHeight / 2 +
          barHeight * i +
          halfTickLength
        );
      })
      .attr("stroke", "black")
      .attr("stroke-width", 1);
  }

  function changeData() {
    ShowHorizontalBarChartNotOverallViewAll();
  }

  return (
    <>
      {isDataValueTypeIdNMBR === true ? (
        "Graphs are not used for the Number Data Type"
      ) : (
        <>
          <div className="horizontalGraphLegendContainer order-1 order-sm-2 print-col-12">
            <div
              className="horizontalGraphLegend print-col-12 print-horizontal-legend"
              style={{
                maxWidth: legendWidth,
                top: margin.top,
                right: margin.right,
              }}
            >
              {stratificationKeys.map((stratification, index) => {
                return (
                  <div className="horizontalLegendItems">
                    <svg viewBox="0 0 100 100" width="15" height="15">
                      <rect
                        className={`color_${index}`}
                        width="100%"
                        height="100%"
                        fill={barColors[index]}
                      />
                    </svg>
                    <span> {stratification}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            id={id}
            className="horizontalGraphViewAll order-3 print-col-12"
          ></div>
        </>
      )}
    </>
  );
};

export default HorizontalBarChartNotOverallViewAll;
