import React, { useEffect } from "react";
import uuid from "react-uuid";

const QuestionTile = ({ question, onChange, isSelected, showingResults, isDisabled }) => {

    useEffect(() => {

    }, [isSelected]);

    function handleCardClick() {
        if (showingResults) return;
        onChange(!isSelected, question.QuestionId);
    }

    if (showingResults && !isSelected) return;

    return (
        <div key={uuid()} className="section-card-container">
            <button 
                value={question.QuestionId}
                className={`section-card btn btn-primary card-not-selected my-1 col-12 ${isDisabled ? " disabled " : ""}`}
                is-selected={!isDisabled  && isSelected.toString()}
                onClick={handleCardClick}
                is-disabled={isDisabled.toString() }
            >
                {question.Question}
            </button>
        </div>
    );
}


export default QuestionTile