import React from "react";
import * as d3 from "d3";
import { useEffect } from "react";

const VerticalBarChart = ({
  datasets,
  id,
  wrap,
  SetChartToolTip,
  ClearLocationToolTip,
  legendSymbols,
  stratificationID,
  deviceType,
}) => {
  useEffect(() => {
    changeData();
  }, [stratificationID]);

  const verticalChartOverflow =
    datasets && datasets.DataItems.length > 4 ? "vertical-chart-overflow" : "";

  const margin = 50;
  const width = 650 - margin * 2;
  const height = 410 - margin * 2;

  function ShowVerticalBarChart(DataItems) {
    const currentChart = document.querySelector("svg#" + id);
    if (currentChart !== null) currentChart.remove();
    const margin = 40;
    const DataValueUnit =
      DataItems[0].DataValueType === "Number" ||
      DataItems[0].DataValueUnit === "gallons"
        ? ""
        : "(" + DataItems[0].DataValueUnit + ")";

    const svg = d3
      .select("#" + id)
      .append("svg")
      .attr("id", id)
      .attr("width", width + margin * 2)
      .attr(
        "height",
        DataItems[0].StratificationCategoryId === "RACE"
          ? height + margin * 3.5
          : height + margin * 2
      );

    const xScale = d3.scaleBand().range([0, width]).padding(0.4);
    const yScale = d3.scaleLinear().range([height, 0]);

    let g = svg
      .append("g")
      .attr("transform", "translate(60 " + margin + ")")
      .on("mouseout", function () {
        ClearLocationToolTip("clear");
      });

    xScale.domain(DataItems.map((d) => d.Stratification));
    yScale.domain([
      0,
      d3.max(DataItems, (d) =>
        d.HighConfidenceLimit === null
          ? d.DataValue + d.DataValue / 10
          : d.HighConfidenceLimit + d.HighConfidenceLimit / 10
      ),
    ]);

    function addFootnoteToLabel(e, index) {
      return DataItems[index].DataValueFootnoteSymbol === ""
        ? `${e}`
        : `${DataItems[index].DataValueFootnoteSymbol} ${e}`;
    }

    console.log(DataItems);
    // X-Axis
    g.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale).tickFormat(addFootnoteToLabel))
      .selectAll(".tick text")
      .attr("font-size", DataItems.length > 5 ? "11px" : "14px")
      .call(wrap, xScale.bandwidth() + 20);

    // Y-Axis
    g.append("g")
      .attr("class", "grid")
      .call(
        d3
          .axisLeft(yScale)
          .tickFormat(function (d) {
            return d;
          })

          .ticks(10)
      )
      .append("text")
      .attr("class", "yAxisLabel")
      .attr("fill", "black")
      .attr("font-size", "15")
      .attr("font-weight", "bold")
      .text(DataItems[0].DataValueType + " " + DataValueUnit);

    g.selectAll(".grid .tick line").attr("x1", "-6").attr("x2", width);

    g.selectAll("rect")
      .data(DataItems)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.Stratification))
      .attr("y", (d) => yScale(d.DataValue))
      .attr("fill", (d, i) => legendSymbols[i].color)
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - yScale(d.DataValue))
      .on("mouseover", function (mouseEvent, locationInfo) {
        if (deviceType === "mobile") return;
        SetChartToolTip(mouseEvent, locationInfo);
      })
      .on("mouseout", function (mouseEvent) {
        if (deviceType === "mobile") return;
        if (mouseEvent.toElement == null) return;
        if (mouseEvent.toElement.id === id) {
          ClearLocationToolTip(mouseEvent);
        }
      })
      .on("click", function (mouseEvent, locationInfo) {
        if (deviceType === "desktop") return;
        const toolTipOffSet = 0.25;
        SetChartToolTip(mouseEvent, locationInfo, toolTipOffSet);
      });

    g.selectAll(".bar")
      .data(DataItems)
      .append("title")
      .attr("fill", "black")
      .attr("font-size", "15")
      .attr("font-weight", "bold")
      .attr("x", (d) => xScale(d.Stratification))
      .attr("y", (d) => yScale(d.DataValue));

    g.selectAll("bar")
      .data(DataItems)
      .enter()
      .append("line")
      .attr("x1", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.Stratification) + xScale.bandwidth() / 2;
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.Stratification) + xScale.bandwidth() / 2;
      })
      .attr("y1", (d) => yScale(d.HighConfidenceLimit))
      .attr("y2", (d) => yScale(d.LowConfidenceLimit))
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    g.selectAll("bar")
      .data(DataItems)
      .enter()
      .append("line")
      .attr("x1", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.Stratification) + xScale.bandwidth() / 2 - 10;
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.Stratification) + xScale.bandwidth() / 2 + 10;
      })
      .attr("y1", (d) => yScale(d.HighConfidenceLimit))
      .attr("y2", (d) => yScale(d.HighConfidenceLimit))
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    g.selectAll("bar")
      .data(DataItems)
      .enter()
      .append("line")
      .attr("x1", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.Stratification) + xScale.bandwidth() / 2 - 10;
      })
      .attr("x2", (d) => {
        if (d.HighConfidenceLimit === null) return 0;
        return xScale(d.Stratification) + xScale.bandwidth() / 2 + 10;
      })
      .attr("y1", (d) => yScale(d.LowConfidenceLimit))
      .attr("y2", (d) => yScale(d.LowConfidenceLimit))
      .attr("stroke", "black")
      .attr("stroke-width", 1);
  }

  function changeData() {
    ShowVerticalBarChart(datasets.DataItems);
  }

  return (
    <div id={id} className={`vertical-chart ${verticalChartOverflow}`}></div>
  );
};

export default VerticalBarChart;
