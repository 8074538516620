import React, { useEffect } from "react";
import './DataVisualizationToggle.css'
import * as analytics from "../util/Analytics/HomeAnalytics";
const DataVisualizationToggle = ({ vizTypeChange, defaultViz, showMapVizType, showGraphVizType, value }) => {

    useEffect(() => {        
        vizTypeChange(value ? value : defaultViz)
    }, []);

    function showMap() {        
        vizTypeChange(1)
        analytics.selectView({ vizType: 'map' })
    }
    function showChart() {        
        vizTypeChange(2)
        analytics.selectView({ vizType: 'graph' })
    }
    function showTable() {        
        vizTypeChange(3)
        analytics.selectView({ vizType: 'table' })
    }

    const removeLeftBorderRadius = showMapVizType === true ? "removeLeftBorderRadius" : "";

    const showMapButton = showMapVizType && showMapVizType === true ? true : false;
    const showGraphButton = showGraphVizType
    // const showGraphButton = showGraphVizType && showGraphVizType === true ? true : false;
    return (
        <div className="text-center d-print-none">
            {showMapButton &&
                <button
                    className={"filter-data-button btn btn-white dataVisualizationButtonDefault removeRightBorderRadius p-1 " + (value === 1 ? "dataVisualizationSelected" : "")}
                    data-toggle="button" aria-pressed={value === 1 ? "true" : "false"}
                    onClick={showMap}>
                    <span className="cdc-icon-map_01"></span> Map
                </button>
            }
            {showGraphButton && 
            <button className={"filter-data-button btn btn-white dataVisualizationButtonDefault removeRightBorderRadius p-1 " + removeLeftBorderRadius + (value === 2 ? " dataVisualizationSelected" : "")}
                data-toggle="button" aria-pressed={value === 2 ? "true" : "false"}
                onClick={showChart}>
                <span className="cdc-icon-chart_01"></span> Graph
            </button>}
            <button className={"filter-data-button btn btn-white dataVisualizationButtonDefault removeLeftBorderRadius p-1 " + (value === 3 ? " dataVisualizationSelected " : "")}
                data-toggle="button" aria-pressed={value === 3 ? "true" : "false"}
                onClick={showTable}>
                <span className="cdc-icon-table-light"></span> Table
            </button>            
        </div>
    );
};

export default DataVisualizationToggle;
