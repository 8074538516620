import React from "react";
import * as d3 from "d3";
import { useEffect } from "react";
import Legend from "./Legend";

const ComparisonLineChart = ({
  datasets,
  id,
  SetChartToolTip,
  ClearLocationToolTip,
  legendSymbols,
  comparisonLineChartDataSet,
  stratificationID,
  deviceType,
}) => {
  useEffect(() => {
    changeData();
  }, [stratificationID]);

  const margin = 60;
  const width = 650 - margin * 2;
  const height = 400 - margin * 2;

  const stratificationWithData = datasets.StratificationItems.filter(
    (item) => item.HasData === true
  ).map((item) => item.Stratification);

  let workingData = comparisonLineChartDataSet.filter((dataItem) =>
    stratificationWithData.includes(dataItem[0].Stratification)
  );

  workingData = workingData.map((dataItem, index) => {
    return dataItem.map((item) => {
      return {
        name: item.Stratification,
        year: item.YearEnd,
        dataValue: item.DataValue,
        dataValueUnit: item.DataValueUnit,
        legendSymbol: {
          symbol: legendSymbols[index].symbol,
          color: legendSymbols[index].color,
        },
        originalDataItem: item,
      };
    });
  });

  const DataValueUnit =
    workingData[0][0].dataValueType === "Number" ||
    workingData[0][0].dataValueUnit === "gallons"
      ? ""
      : "(" + workingData[0][0].dataValueUnit + ")";

  console.log(workingData);
  function ShowComparisonLineChart(DataItems) {
    const currentChart = document.querySelector("svg#" + id);
    if (currentChart !== null) currentChart.remove();

    if (stratificationID !== "VIEWALL") {
      DataItems = DataItems.filter(
        (data) => data.StratificationID === stratificationID
      );
      let newWorkingData = [];
      workingData.forEach((data, i) => {
        if (data[0].originalDataItem.StratificationID === stratificationID) {
          newWorkingData.push(data);
        }
      });
      workingData = newWorkingData;
    }

    const svg = d3
      .select("#" + id)
      .append("svg")
      .attr("id", id)
      .attr("width", width + margin * 2)
      .attr("height", height + margin * 2);

    const xScale = d3.scalePoint().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    const g = svg
      .append("g")
      .attr("transform", "translate(" + margin + "," + margin + ")");

    xScale.domain(DataItems.map((d) => d.YearEnd));
    yScale.domain([
      0,
      d3.max(DataItems, (d) => d.DataValue + d.DataValue / 10),
    ]);

    // Add X Axis
    g.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale));

    // Add Y axis
    g.append("g")
      .call(d3.axisLeft(yScale))
      .append("text")
      .attr("class", "yAxisLabel")
      .attr("fill", "black")
      .attr("font-size", "15")
      .attr("font-weight", "bold")
      .text(DataItems[0].DataValueType + " " + DataValueUnit);

    workingData.forEach((dataSet) => {
      const currentColor = dataSet[0].legendSymbol.color;
      const currentSymbol = dataSet[0].legendSymbol.symbol;
      const data = dataSet.filter((data) => data.dataValue !== null);

      g.append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", currentColor)
        .attr("stroke-width", 1.5)
        .attr(
          "d",
          d3
            .line()
            .x((d) => {
              return xScale(d.year);
            })
            .y((d) => yScale(d.dataValue))
        );

      g.append("g")
        .selectAll("dot")
        .data(data)
        .enter()
        .append("path")
        .attr("d", currentSymbol)
        .attr(
          "transform",
          (d) =>
            "translate(" + xScale(d.year) + ", " + yScale(d.dataValue) + ")"
        )
        .attr("fill", (d) => {
          return d.dataValue === null ? "none" : currentColor;
        })
        .attr("cx", (d) => xScale(d.YearEnd))
        .attr("cy", (d) =>
          d.DataValue === "null" ? "-100" : yScale(d.DataValue)
        )

        .on("mouseover", function (mouseEvent, locationInfo) {
          if (deviceType === "mobile") return;
          SetChartToolTip(mouseEvent, locationInfo.originalDataItem);
        })
        .on("mouseout", function () {
          if (deviceType === "mobile") return;
          ClearLocationToolTip("clear");
        })
        .on("click", function (mouseEvent, locationInfo) {
          if (deviceType === "desktop") return;
          const toolTipOffSet = 0.25;
          SetChartToolTip(
            mouseEvent,
            locationInfo.originalDataItem,
            toolTipOffSet
          );
        });
    });
  }

  function changeData() {
    ShowComparisonLineChart(datasets.DataItems);
  }

  return (
    <>
      <div id={id} className="trendline-chart"></div>
      <Legend workingData={workingData} />
    </>
  );
};

export default ComparisonLineChart;
