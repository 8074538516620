import { doInteraction } from './Analytics';
export function selectMapZoom() {
    doInteraction(`DPH CDI|LEGEND, ZOOM`);
}
export function selectMapClassification({ classificationType }) {
    doInteraction(`DPH CDI|LEGEND, CLASSTYPE, ${classificationType}`);
}
export function selectMapClasses({ numberOfClasses}) {
    doInteraction(`DPH CDI|LEGEND, CLASSES, ${numberOfClasses}`);
}
export function selectMapSelection() {
    doInteraction(`DPH CDI|MAPSELECTION, STATE`);
}
export function selectMapIndicatorLink() {
    doInteraction(`DPH CDI|MAPSELECTION, INDICATORLINK`);
}