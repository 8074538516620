import React, { useEffect, useState, useRef } from "react";
import "./LocationModal.css";
import { GetLocations } from "../util/Api";
import CheckBox from "../util/CheckBox";
import uuid from "react-uuid";

const LocationModal = ({ value, onHide, onSubmit, selectedLocations }) => {
  useEffect(() => {
    if (!locations || locations.length === 0) {
      fetchLocations(selectedLocations);
    }
  }, [selectedLocations]);

  const [loading, setLoadingMessage] = useState(false);
  const [maxSelectionError, setMaxSelectionError] = useState(false);
  const [selectionRequiredError, setSelectionRequiredError] = useState(false);
  const [locations, setLocations] = useState([]);

  const modalBodyRef = useRef(null);
  const clearAllButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);

  async function fetchLocations(locationParameter) {
    try {
      setLoadingMessage(true);
      let locationResponse = await GetLocations();

      const data = locationResponse.data;

      data.forEach((x) => {
        const index = selectedLocations
          .map((x) => {
            return x.LocationAbbr;
          })
          .join(",")
          .indexOf(x.LocationAbbr);
        x.checked = index > -1 ? true : false;
      });

      setLocations(data);
      setLoadingMessage(false);
    } catch (error) {
      console.log(error);
    }
  }
  async function ClearAll() {
    setMaxSelectionError(false);
    setSelectionRequiredError(false);

    let nextLocations = [...locations];

    locations.map(async (location, i) => {
      return (nextLocations[i].checked = false);
    });

    setLocations(nextLocations);
  }
  function handleToggleLocationModal() {
    onHide();
  }
  async function handleCheckChange({ checked, id, data }) {
    let locationData = data ? data : locations;
    setMaxSelectionError(false);
    setSelectionRequiredError(false);

    if (!canCheck && checked) {
      checked = false;
      setMaxSelectionError(true);
    }

    const index = locationData.map((item) => item.LocationAbbr).indexOf(id);
    let nextLocations = [...locationData];
    nextLocations[index].checked = checked;

    await setLocations(nextLocations);
  }
  function handleSubmit() {
    if (checkedCount === 0) {
      setSelectionRequiredError(true);
      return;
    }

    onSubmit(checkedItems);
    onHide();
  }

  function handleEscClose() {
    onHide();
  }

  const [isClearAllButtonShiftTabEvent, setClearAllButtonShiftTabEvent] =
    useState(false);
  const [isCancelButtonTabEvent, setIsTab] = useState(false);
  useEffect(() => {
    const callback = (event) => {
      if (
        event.key === "Tab" &&
        event.shiftKey &&
        event.target.id === "clearAllButton"
      ) {
        setClearAllButtonShiftTabEvent(true);
      } else if (
        event.key === "Tab" &&
        !event.shiftKey &&
        event.target.id === "cancelButton"
      ) {
        setIsTab(true);
      } else {
        setClearAllButtonShiftTabEvent(false);
        setIsTab(false);
      }
    };
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);

  function handleClearAllButonBlur() {
    if (isClearAllButtonShiftTabEvent) {
      cancelButtonRef.current.focus();
    }
  }
  function handleCancelButtonBlur(e) {
    if (isCancelButtonTabEvent) {
      clearAllButtonRef.current.focus();
    }
  }

  document.addEventListener("keydown", (e) => {
    console.log(e);
    if (e.key === "Escape") {
      handleEscClose();
    }
  });

  const checkedItems = locations
    ? locations.filter((item) => item.checked && item.checked === true)
    : [];
  const checkedCount =
    checkedItems && checkedItems.length ? checkedItems.length : 0;
  const canCheck = checkedCount < 4;
  const submitDisabled = checkedCount === 0 ? true : false;
  return (
    <>
      <div
        id="modal-backdrop"
        className="modal-transition modal-backdrop-orange"
        aria-hidden="true"
      ></div>
      <div
        className={"modal fade show"}
        style={{ display: "block" }}
        // data-backdrop="static"
        id="locatonModal"
        role="dialog"
        aria-label="locationSelectorModel"
        aria-labelledby="locatonModalLabel"
        aria-hidden="true"
        ref={modalBodyRef}
      >
        <div className="modal-dialog" role="document" tabIndex={-1}>
          <div className="modal-content">
            <div className="modal-header">Select Locations</div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">Select up to 4 locations</div>
              </div>
              <div className="row mt-1">
                <div className="col-6">({checkedCount} of 4 selected)</div>
                <div className="col-6 text-right">
                  <button
                    id="clearAllButton"
                    className="btn btn-link"
                    autoFocus
                    onClick={ClearAll}
                    tabIndex={0}
                    ref={clearAllButtonRef}
                    onBlur={handleClearAllButonBlur}
                  >
                    Clear All
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="comparison-report-location-selector">
                    {loading ? (
                      "loading..."
                    ) : (
                      <>
                        {locations &&
                          locations.map((location, i) => {
                            return (
                              <CheckBox
                                checked={location.checked}
                                canCheck={canCheck}
                                id={location.LocationAbbr}
                                name={"location"}
                                value={false}
                                classNames={""}
                                label={location.LocationDesc}
                                onChange={handleCheckChange}
                              />
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-0 mt-2 pt-0" style={{ height: "20px" }}>
                <div className="col-12 pt-0 pb-0 mb-0">
                  {maxSelectionError && (
                    <div className="error-message">
                      Only allowed to choose 4 locations.
                    </div>
                  )}
                  {selectionRequiredError && (
                    <div className="error-message">
                      At least one location is required.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={handleSubmit}
                    disabled={submitDisabled}
                    tabIndex={0}
                    id="locationSubmitButton"
                  >
                    Submit
                  </button>
                  <button
                    id="cancelButton"
                    type="button"
                    className="btn btn-white border"
                    onClick={handleToggleLocationModal}
                    tabIndex={0}
                    ref={cancelButtonRef}
                    onBlur={handleCancelButtonBlur}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationModal;
