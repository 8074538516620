import React from "react";
import * as d3 from "d3";
import { useEffect } from "react";

const LineChart = ({
  datasets,
  id,
  SetChartToolTip,
  ClearLocationToolTip,
  barColor,
  stratificationID,
  deviceType,
}) => {
  useEffect(() => {
    changeData();
  }, [stratificationID]);

  const margin = 60;
  const width = 650 - margin * 2;
  const height = 410 - margin * 2;

  function ShowLineChart(DataItems) {
    const currentChart = document.querySelector("svg#" + id);
    if (currentChart !== null) currentChart.remove();

    const DataValueUnit =
      DataItems[0].DataValueType === "Number" ||
      DataItems[0].DataValueUnit === "gallons"
        ? ""
        : "(" + DataItems[0].DataValueUnit + ")";

    const svg = d3
      .select("#" + id)
      .append("svg")
      .attr("id", id)
      .attr("width", width + margin * 2)
      .attr("height", height + margin * 2);

    const xScale = d3.scalePoint().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    const g = svg
      .append("g")
      .attr("transform", "translate(" + margin + "," + margin + ")");

    xScale.domain(DataItems.map((d) => d.YearEnd));
    yScale.domain([
      0,
      d3.max(DataItems, (d) => d.DataValue + d.DataValue / 10),
    ]);

    // Add X Axis
    g.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale));

    // Add Y axis
    g.append("g")
      .call(d3.axisLeft(yScale))
      .append("text")
      .attr("class", "yAxisLabel")
      .attr("fill", "black")
      .attr("font-size", "15")
      .attr("font-weight", "bold")
      .text(DataItems[0].DataValueType + " " + DataValueUnit);

    // Add the line
    g.append("path")
      .datum(DataItems)
      .attr("fill", "none")
      .attr("stroke", "#005046")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .x((d) => xScale(d.YearEnd))
          .y((d) => yScale(d.DataValue))
          .defined((d) => d.DataValue)
      );

    // Add the Dots
    g.append("g")
      .selectAll("dot")
      .data(DataItems)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.YearEnd))
      .attr("cy", (d) => (d.DataValue === "null" ? 0 : yScale(d.DataValue)))
      .attr("r", 7)
      .attr("fill", (d) => {
        return d.DataValue === null ? "none" : barColor;
      })

      .on("mouseover", function (mouseEvent, locationInfo) {
        if (deviceType === "mobile") return;
        SetChartToolTip(mouseEvent, locationInfo);
      })
      .on("mouseout", function () {
        if (deviceType === "mobile") return;
        ClearLocationToolTip("clear");
      })
      .on("click", function (mouseEvent, locationInfo) {
        if (deviceType === "desktop") return;
        const toolTipOffSet = 0.25;
        SetChartToolTip(mouseEvent, locationInfo, toolTipOffSet);
      });
  }
  function changeData() {
    ShowLineChart(datasets.DataItems);
  }

  return <div id={id} className="trendline-chart"></div>;
};

export default LineChart;
